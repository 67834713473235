/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */

import {Box} from "@mui/material"
import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import SAMLApiService from "../../../services/SAMLApiService"
import {authStore} from "../../common/authentication/AuthStore"
import {UserType} from "../../common/authentication/models/UserType"
import {AvantCircularProgress} from "../../common/loaders/AvantCircularProgress"
import {loadingSpinnerStore} from "../../common/loaders/LoadingSpinnerStore"
import {Redirect} from "react-router-dom"
import {productStore} from "../../common/products/ProductStore"
import {LoginProductContentAreaData} from "../../../types/types"
import {SAML_SSO_LOGIN_PRODUCT_ID} from "../../../util/Constants"
import {ReportSubPaths} from "../../reports/ReportSubPaths"

const SAMLLoginPage: React.FC = observer(() => {

    const [targetUrl, setTargetUrl] = useState<string>("")
    const [apiCallsComplete, setApiCallsComplete] = useState(false)
    const [isPortalLogin, setIsPortalLogin] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {

        const params = new URLSearchParams(window.location.search)
        const jwtToken = params.get("jwt")
        const targetUrl = params.get("targetUrl")
        const groupId = Number(params.get("groupId")) || 0
        const productId: number = Number(params.get("productId")) || 0


        if ((jwtToken !== null) && targetUrl !== null) {
            setTargetUrl(targetUrl)
            setApiCallsComplete(true)
            authStore.convertSAMLTokenToAuthUserAndSet(jwtToken)
        } else if (jwtToken !== null) {
            setIsPortalLogin(true)
            SAMLApiService.portalToReportAccess(jwtToken, groupId, productId)
                .then(response => {
                    const { token, reportUrl, loginProductData } = response.data
                    authStore.convertSAMLTokenToAuthUserAndSet(token)
                    setApiCallsComplete(true)
                    setTargetUrl(reportUrl)
                    sessionStorage.setItem("groupId", JSON.stringify(groupId))
                    productStore.setLoginProduct(loginProductData)
                    if (loginProductData.userName !== null && loginProductData.userName !== undefined) {
                        authStore.setAuthUserUserNamePortal(loginProductData.userName)
                    }
                })
                .catch(error => {
                    setApiCallsComplete(false)
                    setError("Error has Occurred, please contact support")
                })
        }

        if(authStore.auth && jwtToken !== null && targetUrl !== null) {

            // TODO: Remove hard coding and handle this appropriately based on SAML info
            loadingSpinnerStore.hideLoadingSpinner = true
            const lpInfo: LoginProductContentAreaData = {
                loginId: authStore.auth.loginId ? authStore.auth.loginId : 0,
                userType: authStore.auth.userType ? authStore.auth.userType : "S",
                productName: "Dashboard",
                productId: SAML_SSO_LOGIN_PRODUCT_ID.value(),
                contentAreaId: 0,
                contentAreaName: "None",
                rostered: true,
                userName: authStore.username
            }
            productStore.setLoginProduct(lpInfo)
        }
    }, [])

    if (targetUrl !== "" && authStore.auth !== null && apiCallsComplete && productStore.driver !== undefined) {
        const params = new URLSearchParams(window.location.search)
        const groupId = Number(params.get("groupId")) || 0
        const loginId = authStore.auth?.loginId
        const productId: number = Number(params.get("productId")) || 0
        const productName = productStore.loginProduct?.productName
        const userName = productStore.loginProduct?.userName
        switch (authStore.auth?.userType) {
            case UserType.S:
                if (isPortalLogin) {
                    return <Redirect to={ReportSubPaths.REPORTS_REDIRECT} />
                } else {
                    // FCPS Testtakers only at this point
                    return <Redirect to="/sso/student" />
                }
            case UserType.T:
                if (isPortalLogin) {
                    return <Redirect to={ReportSubPaths.REPORTS_REDIRECT} />
                } else {
                    // This is the original code for FCPS teacher which was never tested
                    return <Redirect to="/sso/teacher" />
                }
            case UserType.D:
            case UserType.C:
                if (isPortalLogin) {
                    const newUrl = `${targetUrl}&groupId=${groupId}&loginId=${loginId}&productId=${productId}&productName=${productName}&userName=${userName}`
                    return <Redirect to={newUrl} />
                } else {
                    // This is the original code for FCPS teacher which was never tested
                    return <Redirect to="/sso/admin" />
                }
            case UserType.A:
                return <Redirect to="/admin-dashboard" />
            default:
                authStore.logout()
                return <Redirect to="/login" />
        }
    } else {
        return (
            <Box>
                {(error === null) ? <AvantCircularProgress/> : <h1>{error}</h1>}
            </Box>
        )
    }

})

export default React.memo(SAMLLoginPage)
