/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import * as Sentry from "@sentry/browser"
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios"
import jwtDecode from "jwt-decode"
import lodash from "lodash"
import moment from "moment"
import {useEffect, useState} from "react"
import {authStore} from "../app/common/authentication/AuthStore"
import {AuthUser} from "../app/common/authentication/models/AuthUser"
import {AuthUserTakeUuid} from "../app/common/authentication/models/AuthUserTakeUuid"
import {GroupMaster, GroupType} from "../app/common/group/models/GroupMaster"

import {itemStore} from "../app/common/item/ItemStore"
import {ItemContent} from "../app/common/item/models/ItemContent"
import {loadingSpinnerStore} from "../app/common/loaders/LoadingSpinnerStore"
import {messageStore} from "../app/common/messages/MessageStore"
import {sectionStore} from "../app/section/SectionStore"
import {VoiceRecorder} from "../components/voice-recording/VoiceRecorder"
import {setNoConnection} from "../redux/app/actions"
import store from "../redux/store"
import HelperService from "../services/HelperService"
import {
    AdvanceEducator,
    AdvanceReport,
    Answer,
    AnswerResponse,
    ApiAnswerItemResponse,
    ApiErrorResponse,
    ApiFinishSection,
    ApiItem,
    ApiItemContent,
    ApiLicenseKey,
    ApiLogin,
    ApiPanelGraph,
    ApiProductLanguages,
    ApiResponsePayload,
    ApiSampleTestLogins,
    ApiTake,
    ApiTakePanelResponse,
    ApiTestData,
    ApiTwilioPlaybackTemplateResponse,
    ApiTwilioRecordingSidResponse,
    ApiTwilioTokenResponse,
    ApiUpdateItemFileResponse,
    ApiUpdateTestDuration,
    ApiUpdateTimer,
    BulkPanelGraphData, CleverUpdateDataResponse,
    EulaAgreeDateResposne,
    GetLoginProductsRequest,
    GetLoginProductsResponse,
    ItemFormatEnum,
    JeenieReportData,
    JwtSubject,
    JwtToken,
    LicenseKeysRequest,
    LoginDTO,
    PageResults,
    PanelGraphData,
    PanelGraphSummary,
    PanelGraphWithLanguage,
    SsoEntity,
    SSOStudentInClass,
    SsoTeacherClass,
    GroupMasterSSOClass,
    TakeInviteAuthenticationRequest,
    TSeconds,
    UpdateCorrectAnswerRequest,
    UpdateItemContentRequest,
    UpdatePanelGraphResultSummary,
    UpdateTestCodePanelGraphRequest,
    UserDataByPage,
    UserDataFilters,
    UserDataWithGroupsAndTakePanels,
    UserForm,
    UserFormResponse,
    UserGroups,
    UserListItemDTO,
    ProductPackage,
    SpecialLoginDTO,
    TestPackages,
} from "../types/types"
import * as Constants from "../util/Constants"
import {to} from "../util/elvis"
import {log} from "../util/Logging"
import {Email} from "../validation/Email"
import {TakeCode} from "../validation/TakeCode"
import {ContentAreaId, GroupId, ItemContentId, ItemId, PanelGraphId, PanelId, ProductId, TakeId, TakePanelId, UserId} from "../validation/ValidPrimaryKey"
import {UpdateTestCodesDTO} from "../app/adminTestCodeManagement/TestCodeManagement"
import {ManualUpload} from "../components/admin-portal/uploadResponses/UploadResponses"
import {TestTakerEditData} from "../app/adminClientSupportTool/TestTakerEdit"
import {productStore} from "../app/common/products/ProductStore"
import {
    AvantP360ScheduleCreationResponse, P360ScheduleData
} from "../app/proctor/P360CreateSchedule/P360CreateSchedule"
import {UpdatePasswordResults} from "../components/admin-portal/AdminPasswordChange"
import {
    CredlyIssueBadgesResponse,
    CredlyPrevalidationResponse,
    CredlyRow
} from "../components/admin-portal/UploadCredlyFromFile"
import { ContentAreaApiResponse } from "../app/common/contentArea/ContentAreaStore"
import {AdminLoginTableDataApiResponse} from "../types/rest/LoginTypes"
import {NewQueryParams} from "../components/admin-portal/DevTools/LoginTableNew/LoginTableNew"
import {
    SSOEntityReportData,
    SSOReportQueryParams
} from "../components/admin-portal/DevTools/SSOEntityReports/SSOReportEntityUtils"
import {ListOfSsoClassesOrWelcomeCenterAccess} from "../app/sso/student/SSOAuthorizedStudent"


export interface UpdateVoiceRecorderResult {
    msg: string,
    loginId?: number
    invalidVoiceRecorder?: string
}

export interface ResponseMax {
    response: string | null,
    error: Boolean,
    errorDescription: string | null
}
export interface ProductResponse {
    productId : string
    friendlyName: string
}
export interface ResponseData{
    response:Map<String, String> | null ,
    error: Boolean,
    errorDescription: string | null
   }
export interface RosterResponse {
    sheetsProcessed: number,
    totalLines: number,
    linesProcessed: number,
    linesAlreadyEntered: number,
    linesInError: number,
    errors: any
}

export interface RosterRow {
    sheet: number,
    line: number,
    testCode: string,
    password: string,
    resumeKey: string,
    firstName: string,
    lastName: string,
    productName: string
}

export interface PreValidationResult {
    rosterRows: RosterRow[],
    rosterResponse: RosterResponse
}

export interface MsgResponse {
    success?: boolean
    msg: string
}

export interface UploadREsonseInfo {
    imageFile: string,
    takePanelId: number,
    itemId: number
}

export interface UploadsResponse {
    successfulInserts: UploadREsonseInfo[],
    failedScans: UploadREsonseInfo[],
    failedUploads: UploadREsonseInfo[],
    failedInserts: UploadREsonseInfo[],
    noItem: UploadREsonseInfo[],
    noTestTakers: UploadREsonseInfo[],
    responseExists: UploadREsonseInfo[],
    errorMessage: string
}

interface ResetPasswordBody {
    token: string,
    password: string
}

export interface SearchItemResponse {
    itemCount: number,
    items: ApiItem[]
}

export interface SUFUser {
    username: string,
    pw: string
}

export interface SUFRow {
    line?: number,
    language?: string,
    school?: string,
    classOrLocation?: string,
    testEngine?: string,
    openDate?: string,
    closeDate?: string,
    panelGraphId?: number,
    student: SUFUser,
    teacher: SUFUser,
    success?: boolean,
    error?: string,
    productId?: string
}

export interface SUFResult {
    sufRows: SUFRow[],
    state: string,
    districtOrCustomer: string,
    oppCode: string,
    productPackage: string,
    tabNum?: number,
    success: boolean,
    error: string[],
    productId: number
}

export interface SUFResultAndErrors {
    sufResult2: SUFResult,
    success: boolean,
    errors: string[]
}


export default class ApiService {

    static API_URL: URL
    static ACTIVE_REQUESTS: Set<Promise<AxiosResponse | AxiosError>> = new Set<Promise<AxiosResponse | AxiosError>>()
    static MEDIA_URL: URL
    static CDN_BASE_URL: URL

    // These are wrappers on Axios.get and Axios.post to catch the case where we lose the connection
    // to the API. In that case we dispatch a SET_NO_CONNECTION action to the store and never
    // resolve or reject the promise.

    static get = (url: string, withErrorMessages?: boolean, queryParams?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return new Promise((resolve, reject) => {
            axios.get(url, queryParams).then(
                (res: AxiosResponse) => {
                    // @ts-ignore
                    store.dispatch(setNoConnection(false))
                    authStore.refreshToken(url)
                    resolve(res)
                },
                (err: AxiosError) => {
                    if (err.response === undefined) {
                        // @ts-ignore
                        store.dispatch(setNoConnection(true))
                    } else {
                        if (withErrorMessages) {
                            ApiService.displayErrorMessage(err)
                        }
                        reject(err)
                    }
                }
            )
        })
    }


    static post = (url: string, payload?: any, withErrorMessages?: boolean): Promise<AxiosResponse> => {
        return new Promise((resolve, reject) => {
            axios.post(url, payload).then(
                (res: AxiosResponse) => {
                    // @ts-ignore
                    store.dispatch(setNoConnection(false))
                    authStore.refreshToken(url)
                    resolve(res)
                },
                (err: AxiosError) => {
                    if (err.response === undefined) {
                        // @ts-ignore
                        store.dispatch(setNoConnection(true))
                    } else {
                        if (withErrorMessages) {
                            ApiService.displayErrorMessage(err)
                        }
                        reject(err)
                    }
                }
            )
        })
        
    }

    static authorizeCleverSSOUser = () => {

    }

    static displayErrorMessage = (err: AxiosError) => {
        if (err.response != null && err.response.status != null) {
            const status = err.response.status
            if (status === 401) {
                messageStore.setErrorMessage("Unauthorized")
            } else {
                messageStore.setDefaultError()
            }
        }
    }

    static bulkUpdateLogins = (logins: number[], bulkChanges: []) => {
        console.log("bulkUpdateLogins")
    }

    static patch = (url: string, payload?: any): Promise<AxiosResponse> => {
        return new Promise((resolve, reject) => {
            axios.patch(url, payload).then(
                (res: AxiosResponse) => {
                    // @ts-ignore
                    store.dispatch(setNoConnection(false)) 
                    authStore.refreshToken(url)
                    resolve(res)
                },
                (err: any) => {
                    if (err.response === undefined) {
                        // @ts-ignore
                        store.dispatch(setNoConnection(true))
                    } else {
                        reject(err)
                    }
                }
            )
        })
    }

    static delete = (url: string, payload?: any): Promise<AxiosResponse> => {
        return new Promise((resolve, reject) => {
            axios.delete(url, payload).then(
                (res: AxiosResponse) => {
                    // @ts-ignore
                    store.dispatch(setNoConnection(false)) 
                    authStore.refreshToken(url)
                    resolve(res)
                },
                (err: any) => {
                    if (err.response === undefined) {
                        // @ts-ignore
                        store.dispatch(setNoConnection(true))
                    } else {
                        reject(err)
                    }
                }
            )
        })
    }

    static postFile = (url: string, files: Blob| File | FileList): Promise<AxiosResponse> => {
        return new Promise((resolve, reject) => {
            const headers = { 'Content-Type': 'multipart/form-data' }

            const formData = new FormData()

            if(files instanceof FileList) {
                for (let i: number = 0; i < files.length; i++) {
                    formData.append(`file${i}`, files[i])
                }
            }
            else {
                formData.append("file", files)
            }

            // axios.post(url, formData, { headers }).then(
            axios.post(url, formData, {headers}).then(
                (res: AxiosResponse) => {
                    // console.log(res)
                    // @ts-ignore
                    store.dispatch(setNoConnection(false))
                    authStore.refreshToken(url)
                    resolve(res)
                },
                (err: any) => {
                    if (err.response === undefined) {
                        // TODO: Find a fix for the following pattern: @ts-ignore                         // @ts-ignore
                        //                         store.dispatch(setNoConnection(true))
                        // @ts-ignore
                        store.dispatch(setNoConnection(true))
                    } else {
                        reject(err)
                    }
                }
            )
        })
    }

    static put = (url: string, payload?: any): Promise<AxiosResponse> => {
        return new Promise((resolve, reject) => {
            axios.put(url, payload).then(
                (res: AxiosResponse) => {
                    // @ts-ignore
                    store.dispatch(setNoConnection(false)) 
                    authStore.refreshToken(url)
                    resolve(res)
                },
                (err: any) => {
                    if (err.response === undefined) {
                        // @ts-ignore
                        store.dispatch(setNoConnection(true)) 
                    } else {
                        reject(err)
                    }
                }
            )
        })
    }

    static searchLoginByUsernameAndUserType = (usernameSearchString: string, userType: string): Promise<ApiLogin[]> => {
        const url: string = `${ApiService.API_URL}logins/search?usernameSearchString=${usernameSearchString}&userType=${userType}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.get(url).then(
                    (response: AxiosResponse) => {
                        resolve(response.data)
                    },
                    (error: ApiErrorResponse) => {
                        reject(error)
                    }
                )
            )
        })
    }

    static verifyLogin = (loginId: number): Promise<string> => {
        const url: string = `${ApiService.API_URL}logins/verify/${loginId}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.get(url).then(
                    (response: AxiosResponse) => {
                        resolve(response.data)
                    },
                    (error: ApiErrorResponse) => {
                        if (error.response.data) {
                            reject(error.response.data)
                        } else {
                            reject({kind: "LoginValidation",message: "Unidentified Error", errors: null})
                        }
                    }
                )
            )
        })
    }


    static verifyLoginByUUID = (loginId: string): Promise<string> => {
        const url: string = `${ApiService.API_URL}logins/verify-uuid/${loginId}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.get(url).then(
                    (response: AxiosResponse) => {
                        resolve(response.data)

                    },
                    (error: ApiErrorResponse) => {
                        if (error.response.data) {
                            reject(error.response.data)
                        } else {
                            reject({kind: "LoginValidation",message: "Unidentified Error", errors: null})
                        }
                    }
                )
            )
        })
    }

    static updateTestCodeHandwrittenStatus = (data: UpdateTestCodesDTO) => {
        const url: string = `${ApiService.API_URL}admin-tools/all-handwritten-codes/update`
        const result = ApiService.post(url, data)
        return result
    }

    static updateLoginVoiceRecorder = (loginId: number, voiceRecorder: VoiceRecorder): Promise<UpdateVoiceRecorderResult> => {
        const url: string = `${ApiService.API_URL}logins/${loginId}/voice-recorder`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, {voiceRecorder}).then(
                    () => {
                        resolve({msg: `Successfully updated`, loginId})
                    },
                    (error: ApiErrorResponse) => {
                        reject({msg: error, loginId, voiceRecorder})
                    }
                )
            )
        })
    }


    static updateAllowTest = (loginId: number, allowTest: boolean): Promise<MsgResponse> => {
        const url: string = `${ApiService.API_URL}logins/${loginId}/allow-test`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, {allowTest}).then(
                    (result) => {
                        if (result.data === true) {
                            resolve({success: true, msg: `Successfully updated ${loginId}`})
                        } else {
                            reject({success: false, msg: `Could not update ${loginId}`})
                        }
                    },
                    (error: ApiErrorResponse) => {
                        reject({success: false, msg: `${error}, ${loginId}`})

                    }
                )
            )
        })
    }
    static updateRostered = (loginId: number, rostered: boolean): Promise<MsgResponse> => {
        const url: string = `${ApiService.API_URL}logins/${loginId}/update-rostered`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, {rostered})
                    .then((result) => {
                        if (result.data === true) {
                            resolve({success: true, msg: `Successfully updated ${loginId}`})
                        } else {
                            reject({success: false, msg: `Could not update ${loginId}`})
                        }
                    }).catch ((error: ApiErrorResponse) => {
                        reject({success: false, msg: `${error}, ${loginId}`})

                    })
            )
        })
    }

    static updateProctorType = (loginId: number, proctorType: string): Promise<MsgResponse> => {
        const url: string = `${ApiService.API_URL}logins/${loginId}/proctor-type`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, {proctorType}).then(
                    (result) => {
                        if (result.data === true) {
                            resolve({success: true, msg: `Successfully updated ${loginId}`})
                        } else {
                            reject({success: false, msg: `Could not update ${loginId}`})
                        }
                    },
                    (error: ApiErrorResponse) => {
                        reject({success: false, msg: `${error}, ${loginId}`})
                    }
                )
            )
        })
    }


    /**
     * Add an active request but be sure to return it so whatever .then() logic needs to execute can continue doing so
     * Add a brief delay, this should really be in the spinner, but it's boggling my little mind right now.
     *
     * @param {Promise<AxiosResponse | AxiosError>} req
     * @param {boolean} disableSpinner
     * @returns {Promise<AxiosResponse | AxiosError>}
     */
    static addInteractionBlockingRequest = (req: Promise<any>, disableSpinner?: boolean): Promise<any> => {
        if (disableSpinner !== true) {
            ApiService.ACTIVE_REQUESTS.add(req)
            loadingSpinnerStore.hideLoadingSpinner = false
        }

        req.then(
            () => {
                ApiService.blockingRequestCallback(req)
            },
            () => {
                ApiService.blockingRequestCallback(req)
            }
        )
        return req
    }

    static blockingRequestCallback = (req: Promise<any>) => {
        const delayBeforeHidingSpinner = 500 // in millis
        ApiService.ACTIVE_REQUESTS.delete(req)
        setTimeout(() => {
            if (ApiService.ACTIVE_REQUESTS.size === 0) {
                loadingSpinnerStore.hideLoadingSpinner = true
            }
        }, delayBeforeHidingSpinner)
    }

    static getTwilioPlaybackTemplate = (): Promise<ApiTwilioPlaybackTemplateResponse> => {
        const url: string = `${ApiService.API_URL}voice/getPlaybackTemplate`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.get(url).then(
                    (response: AxiosResponse) => {
                        resolve(response.data.data)
                    },
                    (error: ApiErrorResponse) => {
                        reject(error)
                    }
                )
            )
        })
    }

    static getTwilioToken = (): Promise<ApiTwilioTokenResponse> => {
        const url: string = `${ApiService.API_URL}voice/getToken`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.get(url).then(
                    (response: AxiosResponse) => {
                        resolve(response.data.data)
                    },
                    (error: ApiErrorResponse) => {
                        reject(error)
                    }
                )
            )
        })
    }

    static getTwilioRecordingSid = (callSid: string): Promise<ApiTwilioRecordingSidResponse> => {
        const url: string = `${ApiService.API_URL}voice/getRecordingSid` + callSid
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.get(url).then(
                    (response: AxiosResponse) => {
                        resolve(response.data.data)
                    },
                    (error: ApiErrorResponse) => {
                        reject(error)
                    }
                )
            )
        })
    }


    static getLoginProducts(payload: GetLoginProductsRequest): Promise<GetLoginProductsResponse> {
        const url: string = `${ApiService.API_URL}logins/products`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, payload)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    const msg = `Failed to get login products`
                    Sentry.captureException(new Error(msg))
                    reject(error)
                }
            )
        })
    }

    static getSampleTestData = async (): Promise<ApiSampleTestLogins[]> => {
        const url: string = `${ApiService.API_URL}sample-logins`

        const response = await ApiService.addInteractionBlockingRequest(ApiService.get(url))
        return response.data
    }

    static finishSection(takeId: TakeId, panelId: PanelId, finishPanel?: boolean): Promise<ApiFinishSection> {
        let url: string = `${ApiService.API_URL}takes/${takeId}/panels/${panelId}/finish-section`

        let params = []


        if (finishPanel !== undefined) {
            params.push("finish-panel=true")
            url = `${url}?finish-panel=true`
        }
        const textTypes = sectionStore.textTypes
        if (textTypes && textTypes.length > 0) {
            params.push(`item-content-types=${textTypes}`)
        }
        if (params.length > 0) {
            url = `${url}?${params.join("&")}`
        }

        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url)).then(
                (res: AxiosResponse) => {
                    const finishSection: ApiFinishSection = res.data.data
                    resolve(finishSection)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static finishSectionAndPanel(takeId: TakeId, panelId: PanelId): Promise<ApiFinishSection> {
        return ApiService.finishSection(takeId, panelId, true)
    }

    static fetchPanelGraphs(): Promise<ApiPanelGraph[]> {
        const authUser: AuthUser = authStore.auth!!
        let rawUserId = null
        if (authStore.auth && authStore.auth.ssoId && productStore.loginProduct) {
            //This is an SSO user, get the login from the productStore.
            rawUserId = to<number>(productStore.loginProduct.loginId, new Error("UserId is null when fetching panelgraphs"))
        } else {
            rawUserId = to<number>(authUser.loginId, new Error("UserId is null when fetching panelgraphs"))
        }
        const userId = new UserId(rawUserId).value()


        const url: string = `${ApiService.API_URL}users/${userId}/panel-graphs`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (res: AxiosResponse) => {
                    resolve(res.data.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static fetchTake(code: TakeCode, panelGraphId: PanelGraphId, loginId: number | null= null): Promise<ApiTake> {
        const url: string = `${ApiService.API_URL}takes/${code}/panelgraphs/${panelGraphId}`
        // payload loginId is ONLY used for SSO
        const playload:{} = loginId ? {"loginId" : loginId } : {}
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url,playload)).then(
                (res: AxiosResponse) => {
                    resolve(res.data.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }


    static resetTakePanel = (takePanelId: TakePanelId, action: string, comment: string) => {
        const url: string = `${ApiService.API_URL}takepanels/${takePanelId}/reset`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, {action, comment})).then(
                (res: AxiosResponse) => {
                    resolve(res.data.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static creatAvantP360Schedule = (schedule: P360ScheduleData): Promise<AvantP360ScheduleCreationResponse> => {
        const url: string = `${ApiService.API_URL}p360/add-schedule`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, schedule)).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    if (err.response.data && err.response.data.listOfErrors != null && err.response.data.listOfErrors.length > 0) {
                        if (err.response.data.listOfErrors.includes("schedule-title-exists")
                            || err.response.data.listOfErrors.includes("empty-title")
                            || err.response.data.listOfErrors.includes("empty-description")
                            || err.response.data.listOfErrors.includes("empty-days")
                            || err.response.data.listOfErrors.includes("invalid-slots")
                            || err.response.data.listOfErrors.includes("invalid-date")) {
                            // Send the valid error to the front end to mark the request error correctly
                            resolve(err.response.data)
                        } else {
                            reject(err)
                        }
                    } else {
                        reject(err)
                    }
                }
            )
        })
    }

    static resetTake = (takeId: TakeId, comment: string) => {
        const url: string = `${ApiService.API_URL}takes/${takeId}/reset`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, {comment})).then(
                (res: AxiosResponse) => {
                    resolve(res.data.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }


    static searchItems(
        currentPage: number,
        pageSize: number,
        productId?: ProductId,
        contentAreaId?: ContentAreaId,
        panelGraphName?: string,
        panelName?: string,
        format?: ItemFormatEnum,
        nameLike?: string,
        skill?: string,
        level?: string
    ): Promise<SearchItemResponse> {
        const params: string[] = []
        params.push(`currentPage=${currentPage}`)
        params.push(`pageSize=${pageSize}`)

        if (productId) {
            params.push(`productId=${productId}`)
        }

        if (contentAreaId) {
            params.push(`contentAreaId=${contentAreaId}`)
        }

        if (panelGraphName) {
            params.push(`panelGraphName=${panelGraphName}`)
        }

        if (panelName) {
            params.push(`panelName=${panelName}`)
        }

        if (format) {
            params.push(`itemFormat=${format}`)
        }

        if (nameLike) {
            params.push(`itemName=${nameLike}`)
        }

        if (skill) {
            params.push(`skills=${skill}`)
        }

        if (level) {
            params.push(`level=${level}`)
        }

        const qString = params.length > 0 ? `?${params.join("&").replace(/%/g, "%25")}` : ""
        const url: string = `${ApiService.API_URL}items${qString}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static postProfile(takeId: TakeId, profile: any, product: string): Promise<any> {
        const url: string = `${ApiService.API_URL}profiles/${product}/${takeId}`
        return ApiService.addInteractionBlockingRequest(ApiService.post(url, profile))
    }

    static updateTakeState(takeId: number, state: string): Promise<any> {
        const url: string = `${ApiService.API_URL}takes/${takeId}/update-state/${state}`
        return ApiService.get(url)
    }

    static getUser(email: Email): Promise<UserForm> {
        const url: string = `${ApiService.API_URL}users/?email=${email}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (res: AxiosResponse) => {
                    const userForm = HelperService.apiUserFormToUserForm(res.data.data)
                    resolve(userForm)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    static postUser(userForm: UserForm): Promise<UserFormResponse> {
        const apiUserForm = HelperService.userFormToApiUserForm(userForm)
        const url: string = `${ApiService.API_URL}users`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, apiUserForm)).then(
                (res: AxiosResponse) => {
                    const out: UserFormResponse = {
                        status: res.status,
                        id: res.data.data.id
                    }
                    resolve(out)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    static putUser(userForm: UserForm): Promise<UserFormResponse> {
        const apiUserForm = HelperService.userFormToApiUserForm(userForm)
        const url: string = `${ApiService.API_URL}users`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.put(url, apiUserForm)).then(
                (res: AxiosResponse) => {
                    const out: UserFormResponse = {
                        id: res.data.data.id,
                        status: res.status
                    }
                    resolve(out)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    // todo: Duplicate or reuse front end api call here to handle video recording submissions
    static answerItem(answer: Answer, payload: ApiResponsePayload, disableSpinner?: boolean): Promise<AnswerResponse> {
        const takeId: TakeId = answer.takeId
        const panelId: PanelId = answer.panelId
        const format: string = answer.format.toLowerCase()
        const itemId: ItemId = new ItemId(answer.itemId)

        const baseUrl: string = `${ApiService.API_URL}takes/${takeId}/panels/${panelId}/answer/${format}/${itemId}`
        const url: string = payload.answer instanceof Blob ? `${baseUrl}/upload` : baseUrl

        return new Promise((resolve, reject) => {
            const postFunc =
                payload.answer instanceof Blob
                    // Using a Blob here instead of File because MS Edge does not support File()
                    ? ApiService.postFile(url, new Blob([payload.answer]))
                    : ApiService.post(url, payload)

            ApiService.addInteractionBlockingRequest(postFunc, disableSpinner).then(
                (res: AxiosResponse) => {
                    // This method can return null, but it won't here because it is always an answerChoice
                    const apiItemContent: ApiAnswerItemResponse = res.data.data
                    const correctAnswer: ApiItemContent = apiItemContent.correctAnswer
                    const itemContent: ItemContent = HelperService.processItemContentToChoices(
                        correctAnswer,
                        "",
                        ApiService.MEDIA_URL.href,
                        answer.binName
                    )!

                    const out: AnswerResponse = {
                        timeRemaining: apiItemContent.timeRemaining,
                        itemContent
                    }
                    resolve(out)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static updateTimer(takeId: TakeId, panelId: PanelId): Promise<ApiUpdateTimer> {
        const url: string = `${ApiService.API_URL}takes/${takeId}/panels/${panelId}/update-timer`
        return new Promise((resolve, reject) => {
            ApiService.post(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static updateTestDuration(takeId: TakeId, panelId: PanelId, duration: TSeconds): Promise<ApiUpdateTestDuration> {
        const url: string = `${ApiService.API_URL}takes/${takeId}/panels/${panelId}/update-test-duration/${duration}`
        return new Promise((resolve, reject) => {
            ApiService.post(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static createLicenseKeys(
        numKeys: number,
        productId: ProductId,
        groupId: GroupId,
        languageCount: number,
        oppCode: string,
        expirationDate: string
    ): Promise<ApiLicenseKey[]> {
        const url: string = `${ApiService.API_URL}license-keys`
        const licenseKeysRequest: LicenseKeysRequest = {
            numKeys,
            productId: productId.value(),
            expirationDate,
            oppCode,
            groupId: groupId.value(),
            languageCount
        }
        return new Promise((resolve, reject) => {
            ApiService.post(url, licenseKeysRequest).then(
                (res: AxiosResponse) => {
                    resolve(res.data.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static getLicenseKeys(): Promise<ApiLicenseKey[]> {
        const url: string = `${ApiService.API_URL}license-keys`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static validateLicenseKey(licenseKey: string): Promise<ApiLicenseKey> {
        const url: string = `${ApiService.API_URL}license-keys/${licenseKey}`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getCorrectAnswer(takeId: TakeId, panelId: PanelId, itemId: ItemId): Promise<ItemContent> {
        const url: string = `${ApiService.API_URL}takes/${takeId}/panels/${panelId}/correct-answer/mc/${itemId}`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getProductLanguages(productId: ProductId): Promise<ApiProductLanguages> {
        const url: string = `${ApiService.API_URL}products/${productId}/languages`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getProductPackages(): Promise<TestPackages[]> {
        const url: string = `${ApiService.API_URL}product-package/list`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static registerUser(key: string, userForm: UserForm): Promise<number> {
        const apiUserForm = HelperService.userFormToApiUserForm(userForm)
        const url: string = `${ApiService.API_URL}register/${key}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, apiUserForm)).then(
                (res: AxiosResponse) => {
                    resolve(res.status)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    static updateItemContent(itemContentId: ItemContentId, content: string): Promise<void> {
        const url: string = `${ApiService.API_URL}item-contents/${itemContentId}`
        const payload: UpdateItemContentRequest = {
            content
        }
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.patch(url, payload)).then(
                () => {
                    resolve()
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static updateCorrectAnswer(itemId: ItemId, itemContentId: ItemContentId): Promise<void> {
        const url: string = `${ApiService.API_URL}items/${itemId}`
        const payload: UpdateCorrectAnswerRequest = {
            correctAnswerId: itemContentId.value()
        }
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.patch(url, payload)).then(
                () => {
                    itemStore.getItemAuditLog(itemId.value())
                    resolve()
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static sendForgotPasswordEmail(email: string): Promise<void> {
        const url: string = `${ApiService.API_URL}users/forgot-password?email=${email}`.replace("+", "%2B")
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                () => {
                    resolve()
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static patchUserPassword(token: string, password: string): Promise<number> {
        const url: string = `${ApiService.API_URL}users/password`
        const body: ResetPasswordBody = {token: token, password: password}
        const payload = body
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.patch(url, payload)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.status)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static updateItemFile(
        itemContentId: ItemContentId,
        image: File,
        contentDir: string,
        type: string
    ): Promise<ApiUpdateItemFileResponse> {
        const baseUrl: string = ApiService.API_URL.href
        const url: string = `${baseUrl}item-contents/${itemContentId}/file?contentDir=${contentDir}&type=${type}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.postFile(url, image)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getAdvanceDashboardModuleData(loginId: number, contentAreaId: number): Promise<ApiTakePanelResponse[]> {
        const url: string = `${ApiService.API_URL}advance/language-results/${contentAreaId}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.get(url).then(
                    (response: AxiosResponse) => {
                        resolve(response.data.data)
                    },
                    (error: ApiErrorResponse) => {
                        reject(error)
                    }
                )
            )
        })
    }

    static getGroupsByType(type: GroupType): Promise<GroupMaster[]> {
        const url: string = `${ApiService.API_URL}groupmasters/type/${type}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }


    static getGroupsForUser(loginId: number): Promise<UserGroups> {
        const url: string = `${ApiService.API_URL}users/${loginId}/groups`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getUsersForProductId(productId: ProductId): Promise<UserDataWithGroupsAndTakePanels[]> {
        const url: string = `${ApiService.API_URL}users/product/${productId}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getUsersList(testCodeFilter: string, groupIdFilter: number): Promise<UserListItemDTO[]> {
        // `${ApiService.API_URL}users/list?username=7847-%25&userType=S&language=5&active=false&testEngine=cappanel&groupId=6982`
        let url: string = `${ApiService.API_URL}users/list?`
        let params: string[] = []
        if (testCodeFilter) {
            params.push(`username=${encodeURIComponent(testCodeFilter)}`)
        }
        if (groupIdFilter !== 0) {
            params.push(`groupId=${encodeURIComponent(groupIdFilter)}`)
        }
        url += params.join('&')

        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getAdvanceDistrictReport(): Promise<AdvanceReport> {
        const endpointUrl: string = `${ApiService.API_URL}users/advance-district-report`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(endpointUrl)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getAdvanceAdminReport(page: number, size: number, filters: UserDataFilters): Promise<UserDataByPage> {
        const endpointUrl: string = `${ApiService.API_URL}users/advance-admin-report`
        const url = ApiService.createAdvanceReportUrl(page, size, filters, endpointUrl)
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getAdvanceAdminReportFull(page: number, size: number, filters: UserDataFilters): Promise<UserDataByPage> {
        const endpointUrl: string = `${ApiService.API_URL}users/advance-admin-report-full`
        const url = ApiService.createAdvanceReportUrl(page, size, filters, endpointUrl)
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getAdvanceEducatorsReport(userId: number, groupId: number): Promise<AdvanceEducator[]> {
        const endpointUrl: string = `${ApiService.API_URL}users/${userId}/users?groupId=${groupId}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(endpointUrl)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static createAdvanceReportUrl = (page: number, size: number, filters: UserDataFilters, baseUrl: string): string => {
        let url: string = `${baseUrl}?page=${page}&size=${size}`

        if (filters.username && filters.username.length > 0) {
            url += `&username=${filters.username}`
        }
        if (filters.firstName && filters.firstName.length > 0) {
            url += `&firstName=${filters.firstName}`
        }
        if (filters.lastName && filters.lastName.length > 0) {
            url += `&lastName=${filters.lastName}`
        }
        if (filters.stateId) {
            url += `&stateId=${filters.stateId}`
        }
        if (filters.districtId) {
            url += `&districtId=${filters.districtId}`
        }
        if (filters.schoolId) {
            url += `&schoolId=${filters.schoolId}`
        }
        if (filters.languageId) {
            url += `&languageId=${filters.languageId}`
        }
        if (filters.ordering && filters.ordering.length > 0) {
            url += `&ordering=${filters.ordering}`
        } else {
            // to avoid the security warning of using hard coded credentials....
            const value = "true"
            url += `&ordering=username:${value}`
        }
        return url
    }

    static getJeenieReport(loginId: number): Promise<JeenieReportData[]> {
        const url: string = `${ApiService.API_URL}users/${loginId}/jeenie-report`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data.reports)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static submitSelfEvaluation(selfEvaluationQuestion: string, choice: number, takeId: number): Promise<number> {
        if (selfEvaluationQuestion.length === 0 || choice < 0 || takeId <= 0) {
            throw Error("selfEvaluationQuestion.length === 0 || choice < 0 || takeCode <= 0")
        }
        const request = {
            questionName: selfEvaluationQuestion,
            choice
        }
        const url: string = `${ApiService.API_URL}profiles/place/${takeId}/self-evaluation`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, request)).then(
                (res: AxiosResponse) => {
                    resolve(res.status)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    static getEulaAgreeDate(loginId: number): Promise<EulaAgreeDateResposne> {
        const url: string = `${ApiService.API_URL}users/${loginId}/eula-agree-date`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static checkLoginContinueState(loginId: number, loginName: TakeCode): Promise<void> {
        const url: string = `${ApiService.API_URL}logins/${loginId}/continue?loginName=${loginName}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                () => {
                    resolve()
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static checkLoginNewState(loginId: number, loginName: TakeCode): Promise<void> {
        const url: string = `${ApiService.API_URL}logins/${loginId}/new?loginName=${loginName}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                () => {
                    resolve()
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static adminTools_GetLogins({
                                    username = "",
                                    page = 0,
                                    pageSize = 20,
                                    productId = Constants.ADVANCE_PRODUCT_ID
                                }): Promise<PageResults<LoginDTO> | ApiErrorResponse> {
        return new Promise((resolve, reject) => {
            if (!productId) {
                return reject(new Error("adminTools_GetLogins:: Must provide productName"))
            }

            const params = [
                username ? `username=${username}` : null,
                page ? `page=${page}` : null,
                pageSize ? `pageSize=${pageSize}` : null
            ]
                .filter((v) => !!v)
                .join("&")

            const url = [`${ApiService.API_URL}admin-tools/logins/${productId.toString()}`, params]
                .filter((v) => !!v)
                .join("?")

            return ApiService.get(url)
                .then((response: AxiosResponse) => {
                    const pageResult = response.data as PageResults<LoginDTO>
                    return resolve(pageResult)
                })
                .catch(reject)
        })
    }

    static adminTools_ChangeEmail({
                                      productId = Constants.ADVANCE_PRODUCT_ID,
                                      id = 0,
                                      newEmail = ""
                                  }): Promise<LoginDTO | ApiErrorResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!id) {
                    return reject(new Error(`adminTools_ChangeEmail:: Missing id`))
                }

                if (!newEmail) {
                    return reject(new Error(`adminTools_ChangeEmail:: Missing email`))
                }

                const url = [ApiService.API_URL, "admin-tools/logins/username"].join("")
                log.warn("URL", url)

                const parsedProductId = parseInt(productId.toString(), 10)
                if (lodash.isNaN(productId)) {
                    return reject(new Error(`adminTools_ChangeEmail:: productId must be a Long`))
                }

                const loginDto = await ApiService.post(url, {id, username: newEmail, productId: parsedProductId})
                return resolve(loginDto as LoginDTO)
            } catch (e) {
                return reject(e)
            }
        })
    }

    static authenticateWithTakeInviteCode(inviteCode: string): Promise<AuthUserTakeUuid> {
        const inviteCodeAuthenticationRequest: TakeInviteAuthenticationRequest = {
            inviteCode
        }
        const url: string = `${ApiService.API_URL}v1/authenticate/invite-code`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.post(url, inviteCodeAuthenticationRequest)).then(
                (response: AxiosResponse) => {
                    const token: string = response.data.data.token
                    const decoded: JwtToken = jwtDecode(token) as JwtToken
                    const subject: JwtSubject = JSON.parse(decoded.sub)
                    const authUser: AuthUser = {
                        token,
                        tokenTimeRemaining: subject.timeRemaining,
                        expires: moment.unix(decoded.exp).toISOString(),
                        loginId: null,
                        userId: subject.userId as string,
                        userType: subject.userType,
                        eulaAgreeDate: response.data.eulaAgreeDate,
                        userName: subject.userName,
                        ssoType: subject.ssoType,
                        ssoToken: subject.ssoToken,
                        ssoId: subject.ssoId,
                        ssoUserId: subject.ssoUserId,
                        userPermissions: subject.userPermissions
                    }
                    localStorage.setItem("authUser", JSON.stringify(authUser))
                    const authUserTakeId: AuthUserTakeUuid = {
                        authUser,
                        takeUuid: response.data.data.takeUuid
                    }
                    resolve(authUserTakeId)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    static getTestData(takeUuid: string): Promise<ApiTestData> {
        const url: string = `${ApiService.API_URL}v1/takes/${takeUuid}/test-data`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static updateLicenseKeyExpiration = (key: string, expirationDate: string): Promise<ApiLicenseKey[]> => {
        const url: string = `${ApiService.API_URL}license-key-update`
        const body = {
            key,
            expirationDate
        }
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.post(url, body).then(
                    (response: AxiosResponse) => {
                        resolve(response.data.data)
                    },
                    (error: ApiErrorResponse) => {
                        reject(error)
                    }
                )
            )
        })
    }

    static getAdvanceDistrictReportFull(page: number, size: number, filters: UserDataFilters): Promise<UserDataByPage> {
        const endpointUrl: string = `${ApiService.API_URL}users/advance-district-report-full`
        const url = ApiService.createAdvanceReportUrl(page, size, filters, endpointUrl)
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (response: AxiosResponse) => {
                    resolve(response.data.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static uploadAndPreValidateRoster(file: File): Promise<PreValidationResult> {
        const url: string = `${ApiService.API_URL}admin-tools/roster/upload`
        return new Promise((resolve, reject) => {
            ApiService.postFile(url,file).then(
                (response: AxiosResponse) => {
                    console.log(response)
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static uploadAndValidateSUF(file: File): Promise<SUFResult[]> {
        const url: string = `${ApiService.API_URL}codegen/validate-and-check-suf`
        return new Promise((resolve, reject) => {
            //  this needs to be a different function, make a function like postFile that takes in a string and a file
            ApiService.postFile(url,file).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static createExcel(products: SUFResult[]): Promise<File> {
        const url: string = `${ApiService.API_URL}codegen/submit`
        return new Promise((resolve, reject) => {
            ApiService.post(url, products).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    //  takes in selected products from frontend and inserts codes/users into the db
    static submitSUFToCreateCodes(products:SUFResult[]): Promise<SUFResult[]>{
        const url: string = `${ApiService.API_URL}codegen/create-codes`
        return new Promise((resolve, reject) => {
            ApiService.post(url, products).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static processRoster(rosterRows: RosterRow[]): Promise<RosterResponse> {
        const url: string = `${ApiService.API_URL}admin-tools/roster/process`
        const body = rosterRows
        return new Promise((resolve, reject) => {
            ApiService.post(url, body).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static manualUpload(manualUpload: ManualUpload): Promise<UploadsResponse> {
        const url: string = `${ApiService.API_URL}admin/tools/writingprompts/manual-upload`
        return new Promise((resolve, reject) => {
            const headers = { 'Content-Type': 'multipart/form-data' }
            const formData = new FormData()


            formData.append("testtakerId", manualUpload.testtakerId.toString())
            formData.append("itemId", manualUpload.itemId.toString())
            formData.append("file", manualUpload.responseFile)

            // axios.post(url, formData, {headers}).then(
            axios.post(url, formData, { headers }).then(
                (res: AxiosResponse) => {
                    // @ts-ignore
                    store.dispatch(setNoConnection(false))
                    authStore.refreshToken(url)
                    resolve(res.data)
                },
                (err: any) => {
                    if (err.response === undefined) {
                        // @ts-ignore
                        store.dispatch(setNoConnection(true))
                    } else if (err.response.status === 400) {
                        const res: UploadsResponse = {
                            successfulInserts: [],
                            failedScans: [],
                            failedUploads: [],
                            failedInserts: [],
                            noItem: [],
                            noTestTakers: [],
                            responseExists: [],
                            errorMessage: "Bad Request, check TestTaker ID, Item Id, and File to upload."
                        }
                        resolve(res)
                    } else {
                        reject(err)
                    }
                }
            )
        })
    }

    static uploadWritingPrompts(files: FileList): Promise<UploadsResponse> {
        const url: string = `${ApiService.API_URL}admin-tools/writingprompts/upload`
        return new Promise((resolve, reject) => {
            ApiService.postFile(url, files).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static changeLoginPasswords(logins: string[], disable2FA: boolean, newPassword?: string): Promise<UpdatePasswordResults> {
        const url: string = `${ApiService.API_URL}admin-tools/change-passwords`
        const body = {
            logins,
            newPassword,
            disable2FA
        }

        return new Promise((resolve, reject) => {
            ApiService.post(url, body, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getPanelGraphDataByUsername(username: string): Promise<PanelGraphData> {
        const url: string = `${ApiService.API_URL}admin-tools/get-panel-graph-data-by-username?username=${username}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(
                ApiService.get(url).then(
                    (response: AxiosResponse) => {
                        resolve(response.data)
                    },
                    (error: ApiErrorResponse) => {
                        reject(error)
                    }
                )
            )
        })
    }

    static updateTestCodePanelGraph(testCodeToUpdate: string, newTestCode: string, newPanelGraph: string): Promise<string> {
        const url: string = `${ApiService.API_URL}admin-tools/update-test-code-panel-graph`
        const body = {
            testCodeToUpdate,
            newTestCode,
            newPanelGraph
        }

        return new Promise((resolve, reject) => {
            ApiService.post(url, body, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getPanelGraphList(): Promise<Array<PanelGraphSummary>> {
        const url: string = `${ApiService.API_URL}admin-tools/get-panel-graph-list`

        return new Promise((resolve, reject) => {
            ApiService.post(url, {}, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getCurrentPanelGraphs(product_package:string): Promise<PanelGraphWithLanguage[]> {
        const url: string = `${ApiService.API_URL}panel-graphs/list/by-language/${product_package}`

        return new Promise((resolve, reject) => {
            ApiService.post(url, {}, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getGroupRoster(ssoGroupId:number): Promise<SSOStudentInClass[]> {
        const url: string = `${ApiService.API_URL}sso/view-roster/${ssoGroupId}`

        return new Promise((resolve, reject) => {
            ApiService.get(url, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }


    static clientSupportSaveTestTaker(testTakerEditData: TestTakerEditData) {
        const url: string = `${ApiService.API_URL}admin-tools/update-testtakerdata`

        return new Promise((resolve, reject) => {
            ApiService.post(url, testTakerEditData, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static saveTestTaker(testTakerEditData: TestTakerEditData) {
        const url: string = `${ApiService.API_URL}reports/update-testtakerdata`

        return new Promise((resolve, reject) => {
            ApiService.post(url, testTakerEditData, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static setupTest(classGroupId: number, whichPanelGraphId: number, useHandwritten: boolean, estTestDate: Date | null): Promise<AxiosResponse<SsoTeacherClass>> {
        const url: string = `${ApiService.API_URL}sso/setupTest`

        return new Promise((resolve, reject) => {
            ApiService.post(url, {classGroupId, whichPanelGraphId, useHandwritten, estTestDate}, true).then(
                (response: AxiosResponse) => {
                    resolve(response)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static uploadAndPreValidateCredly(file: File): Promise<CredlyPrevalidationResponse> {
        const url = `${ApiService.API_URL}credly/upload`

        return new Promise((resolve, reject) => {
            ApiService.postFile(url, file).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static issueCredlyBadges(payload: CredlyRow[]): Promise<CredlyIssueBadgesResponse[]> {
        const url = `${ApiService.API_URL}credly/issue-badges`

        return new Promise((resolve, reject) => {
            ApiService.post(url, payload).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static adminCreateNewTest(
        testCode: string,
        password: string,
        closeDate: string,
        panelGraph: number,
        handwritten: boolean,
        createTeacher: boolean
    ): Promise<MsgResponse> {
        const url: string = `${ApiService.API_URL}admin-tools/create-test`

        return new Promise((resolve, reject) => {
            ApiService.post(url, {testCode,password,closeDate,panelGraph,handwritten,createTeacher},true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getProctor360Url(): Promise<string> {
        const url = `${ApiService.API_URL}p360/base_url`

        return new Promise((resolve, reject) => {
            ApiService.get(url, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: AxiosError) => {
                    reject(error)
                }
            )
        })
    }

    static submitProctor360SchedulingLink(loginId: number, schedulingLink: string): Promise<AxiosResponse> {
        const url = `${ApiService.API_URL}p360/scheduling_link`
        const payload = {loginId, schedulingLink}

        return new Promise((resolve, reject) => {
            ApiService.post(url, payload, true).then(
                (response: AxiosResponse) => {
                    resolve(response)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static proctor360EmailReminders(takeIds: number[]): Promise<AxiosResponse> {
        const url = `${ApiService.API_URL}p360/email_reminders`
        const payload = takeIds

        return new Promise((resolve, reject) => {
            ApiService.post(url, payload, true).then(
                (response: AxiosResponse) => {
                    resolve(response)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getBulkPanelGraphData(testCodesToUpdate: string): Promise<BulkPanelGraphData> {
        const url: string = `${ApiService.API_URL}admin-tools/get-bulk-panel-graph-data`
        const body = {testCodesToUpdate}

        return new Promise((resolve, reject) => {
            ApiService.post(url, body, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getSSOStudentClassList(): Promise<ListOfSsoClassesOrWelcomeCenterAccess> {
        const url: string = `${ApiService.API_URL}sso/student/classes`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static getSSOTeacherClassList(): Promise<Array<SsoTeacherClass>> {
        const url: string = `${ApiService.API_URL}sso/teacher/classes`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static getSSOUpdateData(): Promise<CleverUpdateDataResponse> {
        const url: string = `${ApiService.API_URL}clever/updateData`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static getSSODistrictClassList(): Promise<Array<SsoTeacherClass>> {
        const url: string = `${ApiService.API_URL}sso/district/classes`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static getSSOEntity(): Promise<SsoEntity> {
        const url: string = `${ApiService.API_URL}sso/district/entity`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static setTestSettings(groupId:number, pkgShortCode: string, allowHandwritten: boolean): Promise<SsoEntity> {
        const url: string = `${ApiService.API_URL}sso/district/test-settings/${groupId}`
        const postVars = {pkgShortCode, allowHandwritten }

        return new Promise((resolve, reject) => {
            ApiService.post(url, postVars).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static bulkUpdateTestCodePanelGraphs(updateTestCodePanelGraphRequests: UpdateTestCodePanelGraphRequest[]): Promise<UpdatePanelGraphResultSummary[]> {
        const url: string = `${ApiService.API_URL}admin-tools/bulk-update-test-code-panel-graphs`
        const body = {updateTestCodePanelGraphRequests}

        return new Promise((resolve, reject) => {
            ApiService.post(url, body, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static allowTest(loginId: number): Promise<boolean> {
        const url: string = `${ApiService.API_URL}assessment/enable/${loginId}`

        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (res: AxiosResponse) => {
                    console.log(res)
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static disallowTest(loginId: number): Promise<boolean> {
        const url: string = `${ApiService.API_URL}assessment/disable/${loginId}`
        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static allowTestingForGroup(): Promise<boolean> {
        const url: string = `${ApiService.API_URL}assessment/enableTestingForCleverDistrict`

        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static disallowTestingForGroup(): Promise<boolean> {
        const url: string = `${ApiService.API_URL}assessment/disableTestingForCleverDistrict`

        return new Promise((resolve, reject) => {
            ApiService.addInteractionBlockingRequest(ApiService.get(url)).then(
                (res: AxiosResponse) => {
                    console.log(res)
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    /**
     * Logs the clicked off of test on blur to the database
     * @param {number} takeId The take id of the current testtaker
     * @param {number} takePanelId The id of the current takepanel
     */
    static eventLogOnBlur(takeId: number, takePanelId: number) {
        const url = `${ApiService.API_URL}event-log/track-test-onblur`
        const payload = {
            takeId: takeId,
            takePanelId: takePanelId,
        }

        return new Promise((resolve, reject) => {
            ApiService.post(url, payload, true).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    /**
     * Gets the total number of times that the test taker clicked out of the takepanel
     * @param {number} takePanelId The id of current takepanel that is being viewed
     * @returns The total count
     */
    static getClickedOutOfTestCount(takePanelId: number): Promise<number> {
        const url: string = `${ApiService.API_URL}event-log/track-test-count?takePanelId=${takePanelId}`

        return new Promise((resolve, reject) => {
            ApiService.get(url, true).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static getResponseMaximumLengthSpeakingSeconds(): Promise<String> {
        const url: string = `${ApiService.API_URL}response-maximum-length/speaking`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }
    static getResponseMaximumLengthExpressiveSeconds(): Promise<ResponseMax> {
        const url: string = `${ApiService.API_URL}response-maximum-length/expressive`
        return new Promise((resolve, reject) => {
            
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }
   
    static getProductData(): Promise<Array<ProductResponse>> {
        const url: string = `${ApiService.API_URL}v1/products`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static getByeByePage(): Promise<ResponseData> {
        const url: string = `${ApiService.API_URL}response-maximum-length/bye-content`
        return new Promise((resolve, reject) => {

            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)

                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }
    static getResponseMaximumLengthWritingCharacters(): Promise<String> {
        const url: string = `${ApiService.API_URL}response-maximum-length/writing`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static getEncryptedPassword = (loginUUID: string): Promise<string> => {
        const url: string = `${ApiService.API_URL}admin-tools/get-encrypted-password/${loginUUID}`

        return new Promise((resolve, reject) => {
            ApiService.get(url, true).then(
                (response: AxiosResponse) => {
                    resolve(response.data)
                },
                (error: ApiErrorResponse) => {
                    reject(error)
                }
            )
        })
    }

    static getSSOClassGroupDataByTestCode(testCode: string): Promise<GroupMasterSSOClass> {
        const url: string = `${ApiService.API_URL}admin-tools/get-sso-class-group-data/${testCode}`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static disconnectSSOLogin(loginId: number): Promise<MsgResponse> {
        const url: string = `${ApiService.API_URL}admin-tools/disconnect-sso-login/${loginId}`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve({msg: res.data})
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static getListOfProductPackages(): Promise<ProductPackage[]> {
        const url: string = `${ApiService.API_URL}product-package/list`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    resolve([])
                }
            )
        })
    }

    static getAdminPortalLoginsListAll(): Promise<SpecialLoginDTO[]> {
        const url: string = `${ApiService.API_URL}admin-tools/admin-portal-login/list-all`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    resolve([])
                }
            )
        })
    }

    static createNewAdminPortalLogin(username: string, firstName: string | null, lastName: string | null): Promise<LoginDTO> {
        const url: string = `${ApiService.API_URL}admin-tools/admin-portal-login/create`

        return new Promise((resolve, reject) => {
            ApiService.post(url, {
                username: username,
                firstName: firstName,
                lastName: lastName
            }).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    static getAdvanceDistrictAdminsListAll(): Promise<SpecialLoginDTO[]> {
        const url: string = `${ApiService.API_URL}admin-tools/advance-district-admin/list-all`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    resolve([])
                }
            )
        })
    }



    static createNewAdvanceDistrictLogin(username: string, districtGroupId: string, firstName: string | null, lastName: string | null): Promise<LoginDTO> {
        const url: string = `${ApiService.API_URL}admin-tools/advance-district-admin/create`

        return new Promise((resolve, reject) => {
            ApiService.post(url, {
                username: username,
                districtGroupId: districtGroupId,
                firstName: firstName,
                lastName: lastName
            }).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    static getPlaceDistrictAdminsListAll(): Promise<SpecialLoginDTO[]> {
        const url: string = `${ApiService.API_URL}admin-tools/place-district-admin/list-all`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    resolve([])
                }
            )
        })
    }

    static createNewPlaceDistrictLogin(username: string, customerGroupId: string, firstName: string | null, lastName: string | null): Promise<LoginDTO> {
        const url: string = `${ApiService.API_URL}admin-tools/place-district-admin/create`

        return new Promise((resolve, reject) => {
            ApiService.post(url, {
                username: username,
                customerGroupId: customerGroupId,
                firstName: firstName,
                lastName: lastName
            }).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    static getSignLanguageDistrictAdminsListAll(): Promise<SpecialLoginDTO[]> {
        const url: string = `${ApiService.API_URL}admin-tools/sign-language-district-admin/list-all`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    resolve([])
                }
            )
        })
    }

    static createNewSignLanguageDistrictLogin(username: string, districtGroupId: string, firstName: string | null, lastName: string | null): Promise<LoginDTO> {
        const url: string = `${ApiService.API_URL}admin-tools/sign-language-district-admin/create`

        return new Promise((resolve, reject) => {
            ApiService.post(url, {
                username: username,
                districtGroupId: districtGroupId,
                firstName: firstName,
                lastName: lastName
            }).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    static getPublicApiLoginsListAll(): Promise<SpecialLoginDTO[]> {
        const url: string = `${ApiService.API_URL}admin-tools/public-api-login/list-all`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    resolve([])
                }
            )
        })
    }

    static createNewPublicApiLogin(username: string, groupIds: string, organizationContactName: string | null, organizationName: string | null): Promise<LoginDTO> {
        const url: string = `${ApiService.API_URL}admin-tools/public-api-login/create`

        return new Promise((resolve, reject) => {
            ApiService.post(url, {
                username: username,
                groupIds: groupIds,
                organizationContactName: organizationContactName,
                organizationName: organizationName
            }).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err) => {
                    reject(err)
                }
            )
        })
    }

    static getContentAreas(): Promise<ContentAreaApiResponse> {
        const url: string = `${ApiService.API_URL}contentAreas`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    resolve(err.response?.data)
                }
            )
        })
    }

    static convertObjectToQueryString(obj: Record<string, any>): string {
        const paramArray = []

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const curVal = obj[key]

                if (curVal !== undefined && curVal !== null) {
                    if(typeof curVal === "object") {
                        for (const subKey in curVal) {
                            if (curVal.hasOwnProperty(subKey)) {
                                const subVal = curVal[subKey]
                                if(subVal !== undefined && subVal !== null) {
                                    paramArray.push(`${encodeURIComponent(key)}%5B${encodeURIComponent(subKey)}%5D=${encodeURIComponent(subVal)}`)
                                }
                            }
                        }
                    } else if (typeof curVal === "boolean") {
                        paramArray.push(`${encodeURIComponent(key)}=${curVal ? "true" : "false"}`)
                    } else {
                        paramArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(curVal)}`)
                    }
                }
            }
        }

        return paramArray.join("&")
    }

    static getAdminToolLoginTableData(queryParams: NewQueryParams): Promise<AdminLoginTableDataApiResponse> {


        const params: String = this.convertObjectToQueryString(queryParams)
        // console.log("Query Params: ", JSON.stringify(queryParams))
        // console.log("Query Params as String: ", params)

        const endPoint: string = "admin-panel-tools/admin-tools/get-login-table-data"
        const urlWithParams: string = params !== undefined && params !== "" ? `${ApiService.API_URL}${endPoint}?${params}` : `${ApiService.API_URL}${endPoint}`


        return new Promise((resolve, reject) => {
            ApiService.get(urlWithParams, true,{  }).then(
                (res: AxiosResponse) => {
                    // console.log("Response Data: ", res.data)
                    resolve(res.data)
                },
                // (err: ApiErrorResponse) => {
                //     resolve()
                // }
            )
        })
    }

    static getSSOEntityReportingData(queryParams: SSOReportQueryParams): Promise<SSOEntityReportData> {
        const params: String = this.convertObjectToQueryString(queryParams)

        const endPoint: string = "sso-entity-report"
        const urlWithParams: string = params !== undefined && params !== "" ? `${ApiService.API_URL}${endPoint}?${params}` : `${ApiService.API_URL}${endPoint}`

        return new Promise((resolve, reject) => {
            ApiService.get(urlWithParams, true,{  }).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
            )
        })
    }
}

// getBulkPanelGraphData(testCodesToUpdate)

export function useHttpGet<T>(url: string): [boolean, T | undefined] {
    const [results, setResult] = useState<T | undefined>()
    const [loading, setLoading] = useState(true)

    const fetchPrompts = async () => {
        const response = await ApiService.get(url)
        setResult(response.data)
        setLoading(false)
    }

    useEffect(() => {
        fetchPrompts()
    }, [])

    return [loading, results]
}
