import {createStyles, WithStyles, withStyles} from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import {ItemContent} from "../../app/common/item/models/ItemContent"
import {ProductDriver} from "../../app/common/products/ProductStore"
import ApiService from "../../services/ApiService"
import {
    Answer,
    IItem,
    ItemContentTypeEnum,
    ItemFormatEnum,
    LoginProductContentAreaData,
    TestEngineOptions,
    TLocalAnswer
} from "../../types/types"
import {APT_PRODUCT_ID, CONTENT_AREA_ID_ARABIC} from "../../util/Constants"
import {to} from "../../util/elvis"
import {ItemId, PanelId, TakeId} from "../../validation/ValidPrimaryKey"
import AdvanceMoreModal from "../advance/AdvanceMoreModal"
import AudioPlayer from "../AudioPlayer/AudioPlayer"
import Button from "../Button/Button"

const styles = createStyles({
    arabicFontSize: {
        fontSize: 20,
        lineHeight: 1.5
        // height: 68
    }
})

type Props = {
    isAdmin: boolean
    item: IItem
    answer: TLocalAnswer | null
    prevAnswer: Answer | null
    choices: ItemContent[]
    config: TestEngineOptions
    correctAnswers?: string[]
    driver: ProductDriver
    panelId?: number
    takeId?: number
    product: LoginProductContentAreaData
    handleAnswerChange: (answer: TLocalAnswer | null) => void
    handleEditCorrectAnswer: (newCorrectAnswer: ItemContent, oldCorrectAnswer: ItemContent) => Promise<void>
    hasAudio: (choices: ItemContent[]) => boolean
} & WithStyles<typeof styles>

interface IComponentState {
    error: boolean | string
    editingRadioCorrectAnswer: boolean
    editableCorrectAnswer: ItemContent | null
    oldEditableCorrectAnswer: ItemContent | null
    correctAnswers: string[]
    index: number
    showMoreModal: boolean
}

class SingleSelectGroup extends React.Component<Props, IComponentState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            error: false,
            correctAnswers: props.correctAnswers || [],
            editingRadioCorrectAnswer: false,
            editableCorrectAnswer: null,
            oldEditableCorrectAnswer: null,
            index: 0,
            showMoreModal: false
        }
    }

    componentDidUpdate() {
        if (this.props.item.correctAnswers !== this.state.correctAnswers) {
            this.setState({correctAnswers: this.props.item.correctAnswers})
        }
    }

    editCorrectAnswer = () => {
        const {takeId, panelId, item, driver} = this.props
        if (item.format === ItemFormatEnum.MULTIPLE_CHOICE && takeId != null && panelId != null) {
            ApiService
                .getCorrectAnswer(
                    new TakeId(takeId),
                    new PanelId(panelId),
                    new ItemId(item.id)
                )
                .then((editableCorrectAnswer: ItemContent) => {
                    this.setState({
                        editingRadioCorrectAnswer: true,
                        editableCorrectAnswer,
                        oldEditableCorrectAnswer: editableCorrectAnswer
                    })
                })
        } else {
            this.setState({
                error: `Sorry, an error occurred. Please contact ${driver.config.SUPPORT_EMAIL}.`
            })
            throw new Error(`Tried to edit an non-MC item's correct answer.`)
        }
    }

    saveCorrectAnswer = () => {
        const newCorrectAnswer: ItemContent = to<ItemContent>(
            this.state.editableCorrectAnswer,
            new Error("Did not find the editable correct answer")
        )
        const oldCorrectAnswer: ItemContent = to<ItemContent>(
            this.state.oldEditableCorrectAnswer,
            new Error("Did not find the old editable correct answer")
        )
        const correctAnswers: string[] = this.state.correctAnswers
        if (correctAnswers.length > 0) {
            correctAnswers[0] = newCorrectAnswer.id + ""
        }
        this.props.handleEditCorrectAnswer(newCorrectAnswer, oldCorrectAnswer).then(() => {
            this.setState({editingRadioCorrectAnswer: false, correctAnswers})
        })
    }

    cancelEditCorrectAnswer = () => {
        this.setState({editingRadioCorrectAnswer: false})
    }

    handleCorrectAnswerChange = (editableCorrectAnswer: ItemContent) => {
        this.setState({editableCorrectAnswer})
    }

    handleChoiceSelect = (id: number, isDisabled: boolean) => {
        if (!isDisabled) {
            this.props.handleAnswerChange(id)
        }
    }

    handleChoiceSelectKeyPress = (id: number, isDisabled: boolean) => {
        if (!isDisabled) {
            this.props.handleAnswerChange(id)
        }
    }

    showMoreModal = (index: number) => {
        this.setState({index, showMoreModal: !this.state.showMoreModal})
    }

    renderChoice = (choice: ItemContent, hasAudio: boolean, isLastChoice: boolean, index: number) => {
        const {product, classes, correctAnswers} = this.props

        if (choice.type === ItemContentTypeEnum.TEXT) {
            choice.content = choice.content ? choice.content.toString().replace("<div", "<span") : ""
            choice.content = choice.content ? choice.content.toString().replace("</div", "</span") : ""
        }
        let label = <span dangerouslySetInnerHTML={{__html: (choice.content as string) || ""}}/>
        if (choice.type === ItemContentTypeEnum.PLAIN_IMAGE) {
            label = <img style={{maxWidth: "200px"}} src={choice.content as string} alt=""/>
        }
        if (choice.type.includes(ItemContentTypeEnum.TEXT_SEND) || choice.type.includes(ItemContentTypeEnum.SOUND_TEXT)) {
            label = <span dangerouslySetInnerHTML={{__html: choice.description || ""}}/>
        }

        let isChecked = false
        if (this.props.answer === choice.id && this.props.item.binName === choice.binName) {
            isChecked = true
        }

        let choiceClass = "single-select__container__choice"
        let moreClass = "single-select__container__more"

        if (isLastChoice && !hasAudio) {
            choiceClass = `${choiceClass} single-select__container__choice--last`
            moreClass = `${moreClass} single-select__container__more--last`
        }
        if (isLastChoice && hasAudio) {
            choiceClass = `${choiceClass} single-select__container__choice--last-audio`
        }
        if (isChecked) {
            choiceClass = `${choiceClass} single-select__container__choice--checked`
            moreClass = `${moreClass} single-select__container__more--checked`
        }
        if (!isLastChoice && hasAudio) {
            choiceClass = `${choiceClass} single-select__container__choice--audio`
        }

        let buttonClassName = "multi-select__container__audio-player"

        if (isLastChoice) {
            buttonClassName = `${buttonClassName} multi-select__container__audio-player--last`
        }
        if (isChecked) {
            buttonClassName = `${buttonClassName} multi-select__container__audio-player--checked`
        }

        const isCorrectAnswer = (correctAnswers) ? correctAnswers.indexOf(choice.id.toString()) !== -1 : []

        if (isCorrectAnswer) {
            choiceClass = `${choiceClass} debug-correct-answer`.trim()
        }
        if (choice.type.includes(ItemContentTypeEnum.RTL)) {
            choiceClass = `${choiceClass} direction-rtl`.trim()
        }

        if (product.productId === APT_PRODUCT_ID.value() || product.contentAreaId === CONTENT_AREA_ID_ARABIC) {
            choiceClass = classNames(choiceClass, classes.arabicFontSize)
        }

        let hasMoreContent: boolean = false
        if (this.props.config.hasMoreContent) {
            hasMoreContent = true
        }

        let isDisabled = false
        if (this.props.config.disableReansweringItem && this.props.prevAnswer) {
            isDisabled = true
        }

        let onClick = () => this.handleChoiceSelect(choice.id, isDisabled)
        let onKeyPress = () => this.handleChoiceSelectKeyPress(choice.id, isDisabled)
        if (this.state.editingRadioCorrectAnswer && this.state.editableCorrectAnswer) {
            isChecked = this.state.editableCorrectAnswer.id === choice.id
            onClick = () => this.handleCorrectAnswerChange(choice)
            onKeyPress = () => this.handleCorrectAnswerChange(choice)
        }

        return (
            <div key={Math.random()} className="single-select__container">
                <div className={choiceClass} onClick={onClick} data-tst-id="selectable-response" tabIndex={0}
                     onKeyPress={onKeyPress}>
                    <div className="single-select__container__choice__text">
                        {isCorrectAnswer && !this.state.editingRadioCorrectAnswer ? " !!! " : ""}
                        {label}
                    </div>
                    {isChecked && (
                        <div className="single-select__container__choice__check">
                            <i className="material-icons">done</i>
                        </div>
                    )}
                </div>
                {hasAudio && (
                    <AudioPlayer
                        id={choice.id}
                        src={choice.content as string}
                        buttonClassName={buttonClassName}
                        iconClassName="multi-select__container__audio-player__icon"
                        audioClassName="audio-button"
                    />
                )}
                {hasMoreContent && (
                    <div className={moreClass} onClick={() => this.showMoreModal(index)}>
                        <i className="material-icons single-select__container__more__icon">info</i>
                    </div>
                )}
            </div>
        )
    }

    render() {
        const choices: ItemContent[] = this.props.choices
        const hasAudio: boolean = this.props.hasAudio(choices)

        return (
            <div className="single-select" data-tst-id="choices-wrapper">
                {this.state.editingRadioCorrectAnswer ? (
                    <div>
                        <Button
                            className="avant-button--primary avant-button--small margin-right"
                            onClick={this.cancelEditCorrectAnswer}
                        >
                            Cancel
                        </Button>
                        <Button className="avant-button--primary avant-button--small" onClick={this.saveCorrectAnswer}>
                            Save
                        </Button>
                    </div>
                ) : (
                    <div>
                        {this.props.isAdmin && (
                            <Button
                                className="avant-button--primary avant-button--small"
                                onClick={this.editCorrectAnswer}
                            >
                                Edit
                            </Button>
                        )}
                    </div>
                )}
                {choices.map((choice: ItemContent, index: number) => {
                    const isLastChoice: boolean = index === choices.length - 1
                    return this.renderChoice(choice, hasAudio, isLastChoice, index)
                })}
                <AdvanceMoreModal
                    index={this.state.index}
                    showModal={this.state.showMoreModal}
                    handleModalVisibility={this.showMoreModal}
                />
            </div>
        )
    }
}

export default withStyles(styles)(SingleSelectGroup)
