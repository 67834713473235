import {createStyles, WithStyles, withStyles} from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import {ItemContent} from "../../app/common/item/models/ItemContent"
import { Answer, IItem, ItemContentTypeEnum, LoginProductContentAreaData, TestEngineOptions, TLocalAnswer } from "../../types/types"
import {APT_PRODUCT_ID, CONTENT_AREA_ID_ARABIC} from "../../util/Constants"
import AudioPlayer from "../AudioPlayer/AudioPlayer"

const styles = createStyles({
    arabicFontSize: {
        fontSize: 20,
        lineHeight: 1.5,
        // height: 68
    }
})

interface MultiSelectGroupProps extends WithStyles<typeof styles> {
    item: IItem
    choices: ItemContent[]
    config: TestEngineOptions
    answer: TLocalAnswer
    prevAnswer: Answer | null
    correctAnswers: string[]
    product: LoginProductContentAreaData
    handleAnswerChange: (localAnswer: TLocalAnswer) => void
    hasAudio: (choices: ItemContent[]) => boolean
}

interface MultiSelectGroupState {
    answers: TLocalAnswer
    correctAnswers: string[]
}

class MultiSelectGroup extends React.Component<MultiSelectGroupProps, MultiSelectGroupState> {
    constructor(props: MultiSelectGroupProps) {
        super(props)
        this.state = {
            answers: [],
            correctAnswers: props.correctAnswers
        }
    }

    static getDerivedStateFromProps(nextProps: MultiSelectGroupProps, currentState: MultiSelectGroupState): Partial<MultiSelectGroupState> | null {
        if (!Array.isArray(nextProps.answer)) {
            return null
        }

        const answers = nextProps.answer as number[]
        const validAnswers: number[] = []
        const choices = new Set<number>(
            nextProps.choices.map((choice: ItemContent) => choice.id)
        )
        answers.forEach((answer: number) => {
            if (choices.has(answer)) {
                validAnswers.push(answer)
            }
        });

        return { answers: validAnswers }
    }

    handleChoiceSelect = (id: number, isDisabled: boolean) => {
        if (!isDisabled) {
            const answerArray: number[] = this.state.answers ? (this.state.answers as number[]) : []
            const index: number = answerArray.indexOf(id)
            if (index > -1) {
                answerArray.splice(index, 1)
            } else {
                answerArray.push(id)
            }
            const answers = answerArray as TLocalAnswer
            this.setState({answers})
            this.props.handleAnswerChange(answers)
        }
    }

    renderChoice = (choice: ItemContent, hasAudio: boolean, isLastChoice: boolean) => {
        const {product, classes} = this.props

        if (choice.type === ItemContentTypeEnum.TEXT) {
            choice.content = choice.content ? choice.content.toString().replace("<div", "<span").replace("</div", "</span") : ""
        }
        let label = <span dangerouslySetInnerHTML={{__html: (choice.content as string) || ""}}/>

        // TODO remove this after implementing picture select
        if (choice.type === ItemContentTypeEnum.PLAIN_IMAGE) {
            label = <img style={{maxWidth: "200px"}} src={choice.content as string} alt=""/>
        }

        if (choice.type.includes(ItemContentTypeEnum.TEXT_SEND) || choice.type.includes(ItemContentTypeEnum.SOUND_TEXT)) {
            label = <span dangerouslySetInnerHTML={{__html: choice.description || ""}}/>
        }

        let isChecked = false
        let answers: number[] = []
        if (Array.isArray(this.props.answer)) {
            answers = this.props.answer as number[]
        }
        answers.forEach((answer: number) => {
            if (answer === choice.id && this.props.item.binName === choice.binName) {
                isChecked = true
            }
        })

        let choiceClass = "multi-select__container__choice"
        if (isLastChoice && !hasAudio) {
            choiceClass = `${choiceClass} multi-select__container__choice--last`
        }
        if (isLastChoice && hasAudio) {
            choiceClass = `${choiceClass} multi-select__container__choice--last-audio`
        }
        if (isChecked) {
            choiceClass = `${choiceClass} multi-select__container__choice--checked`
        }
        if (!isLastChoice && hasAudio) {
            choiceClass = `${choiceClass} multi-select__container__choice--audio`
        }

        let buttonClassName = "multi-select__container__audio-player"

        if (isLastChoice) {
            buttonClassName = `${buttonClassName} multi-select__container__audio-player--last`
        }
        if (isChecked) {
            buttonClassName = `${buttonClassName} multi-select__container__audio-player--checked`
        }

        const isCorrectAnswer = this.props.correctAnswers ? this.props.correctAnswers.indexOf(choice.id.toString()) !== -1 : null
        if (isCorrectAnswer) {
            choiceClass = `${choiceClass} debug-correct-answer`.trim()
        }
        if (choice.type.includes(ItemContentTypeEnum.RTL)) {
            choiceClass = `${choiceClass} direction-rtl`.trim()
        }

        if (product.productId === APT_PRODUCT_ID.value() || product.contentAreaId === CONTENT_AREA_ID_ARABIC) {
            choiceClass = classNames(choiceClass, classes.arabicFontSize)
        }

        let isDisabled = false
        if (this.props.config.disableReansweringItem && this.props.prevAnswer) {
            isDisabled = true
        }

        return (
            <div key={Math.random()} className="multi-select__container">
                <div className={choiceClass} data-tst-id={choice.id}
                     onClick={() => this.handleChoiceSelect(choice.id, isDisabled)}>
                    <div className="multi-select__container__choice__text">
                        {isCorrectAnswer ? " !!! " : ""}
                        {label}
                    </div>
                    {isChecked && (
                        <div className="multi-select__container__choice__check">
                            <i className="material-icons">done</i>
                        </div>
                    )}
                </div>
                {hasAudio && (
                    <AudioPlayer
                        id={choice.id}
                        src={choice.content as string}
                        buttonClassName={buttonClassName}
                        iconClassName="multi-select__container__audio-player__icon"
                        audioClassName="audio-button"
                    />
                )}
            </div>
        )
    }

    render() {
        const choices: ItemContent[] = this.props.choices
        const hasAudio: boolean = this.props.hasAudio(choices)
        return (
            <div className="multi-select" data-tst-id="choices-wrapper">
                {choices.map((choice: ItemContent, index: number) => {
                    const isLastChoice: boolean = index === choices.length - 1
                    return this.renderChoice(choice, hasAudio, isLastChoice)
                })}
            </div>
        )
    }
}

export default withStyles(styles)(MultiSelectGroup)
