/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {
    Checkbox,
    Grid, InputLabel, List, ListItem, ListItemText,
    MenuItem,
    Paper, Select
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import {DatePicker} from '@mui/x-date-pickers'
import {Moment} from "moment"
import * as React from "react"
import {RefObject, useEffect, useRef, useState} from "react"
import {messageStore} from "../../../app/common/messages/MessageStore"
import {SSOBulkConfirm} from "../../../app/sso/districtAdmin/components/SSOBulkConfirm"
import ApiService from "../../../services/ApiService"
import {H2Text} from "../../../styles/AvantTypography"
import {BulkP360Controls} from "./Components/BulkP360Controls"
import {LoginTable} from "./LoginTable"
import {ApiLogin, P360Scheduling} from "../../../types/types"
import DevToolsApi from "../../../services/DevToolsApi"
import Proctor247CreationTool from "./Components/Proctor247CreationTool"
import {theme} from "../../../styles/MuiThemes"
import {UserType} from "../../../app/common/authentication/models/UserType"
import MaterialTable from "material-table"

export enum TeacherPermissions {
    EDIT = "edit-test-takers",
    ROSTER = "roster-test-takers",
}

export enum TestTakerPermissions {
    TRACK = "track-test-takers",
}

export interface PermissionsInterface {
    permissionDisplayText: string
    permissionEnumValue: TestTakerPermissions | TeacherPermissions
}

export const TeacherPermissionsObject: PermissionsInterface[] = [
    {
        permissionDisplayText: "Edit Test Takers",
        permissionEnumValue: TeacherPermissions.EDIT,
    },
    {
        permissionDisplayText: "Roster Test Takers",
        permissionEnumValue: TeacherPermissions.ROSTER,
    },
]

export const TestTakerPermissionsObject: PermissionsInterface[] = [
    {
        permissionDisplayText: "Track Test Takers",
        permissionEnumValue: TestTakerPermissions.TRACK,
    },
]

const Permissions = { ...TeacherPermissions, ...TestTakerPermissions }

interface BulkControlsProps {
    tableRef: RefObject<MaterialTable<ApiLogin>>
    selectedIds: any[]
}

interface Patch {
    handwritten?: boolean
    loginexpires?: string | null
    rostered?: boolean
    permissions?: string[] | null
}

const BulkUpdateControls: React.FC<BulkControlsProps> = ({selectedIds, tableRef}) => {
    const isCanceled = useRef(false)
    const [bulkConfirmation, setBulkConfirmation] = useState(false)
    const [bulkHandwritten, setBulkHandwritten] = useState<boolean|null|unknown|string>("")
    const [bulkLoginExpires, setBulkLoginExpires] = useState<Moment|null>(null)
    const [bulkRoster, setBulkRoster] = useState<boolean|unknown>("")
    const [bulkPermissions, setBulkPermissions] = useState<string[]>([])
    // const [bulkProctorType, setBulkProctorType] = useState<boolean|null|unknown>("")

    const handleBulkConfirmationClick = () => { setBulkConfirmation(true) }
    const closeBulkConfirmationCancel = () => { setBulkConfirmation(false) }
    const closeBulkConfirmationProceed = async () => {
        setBulkConfirmation(false)
        bulkUpdate()
    }

    const setPermissions = (permission: string): void => {
        const permissions = [...bulkPermissions]
        const index = permissions.indexOf(permission)

        if (index === -1) {
            permissions.push(permission)
        } else {
            permissions.splice(index, 1)
        }

        setBulkPermissions(permissions)
    }

    const bulkUpdate = () => {
        const patch: Patch = {}
        const selected = [...selectedIds] // get a safe copy

        //build the patch from the build controls
        if (bulkHandwritten && bulkHandwritten !== "") { patch.handwritten = bulkHandwritten as boolean && true }
        // if (bulkProctorType && bulkProctorType !== "") { patch = {...patch, proctorType: bulkProctorType === "_NONE_" ? null : bulkProctorType }}
        if (bulkLoginExpires) {
            const pst = bulkLoginExpires.add(7, "hours") // convert to PT
            patch.loginexpires = pst.toISOString()
        }

        if (bulkRoster) { patch.rostered = bulkRoster as boolean && true }

        if (selected.length && (Object.keys(patch).length || bulkPermissions.length)) {
            // run all the patches asynchronously
            Promise.all(
                selected.map(async (rowData: any) => {
                    const patchCopy = { ...patch};

                    // Get the permissions that are already on the login
                    const rowPermissions: string[] = rowData.permissions ? rowData.permissions : []

                    // Iterate through the selected permissions
                    for (const permission of bulkPermissions) {
                        // If the login already has one of the selected permissions, then that means we remove that permission from the login.
                        const index: number = rowPermissions.indexOf(permission)
                        if (index >= 0) {
                            rowPermissions.splice(index, 1)
                        }

                        // Here we're adding the permission if it isn't on the login
                        if (index === -1) {
                            // If a student login BUT not a testtaker permission
                            if (rowData.userType === UserType.S && !Object.values(TestTakerPermissions).includes(permission as TestTakerPermissions)) {
                                continue
                            }

                            // If a teacher login BUT not a teacher permission
                            if (rowData.userType === UserType.T && !Object.values(TeacherPermissions).includes(permission as TeacherPermissions)) {
                                continue
                            }

                            rowPermissions.push(permission)
                        }
                    }

                    patchCopy.permissions = [...rowPermissions]

                    await ApiService.patch(rowData._links.self.href, patchCopy)
                })
            ).then(_ => {
                if (!isCanceled.current) {
                    messageStore.setInfoMessage(`Updated ${selected.length} Rows`)
                    // @ts-ignore
                    if (tableRef.current) { tableRef.current.onQueryChange() }
                }
            })
        }
    }

    return (
        <Grid item={true} xs={12}>
            <Paper elevation={3} style={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            }}>
                <H2Text>Bulk Change Tool</H2Text>
                <Grid container={true} spacing={3}>
                    <Grid item={true} xs={4}>
                        <InputLabel shrink={true}>Enable Handwritten</InputLabel>
                        <Select
                            value={bulkHandwritten}
                            displayEmpty={true}
                            onChange={(e) => setBulkHandwritten(e.target.value)}
                        >
                            <MenuItem key={""} value={""}>No change</MenuItem>
                            <MenuItem key={0} value={"true"}>True</MenuItem>
                            <MenuItem key={1} value={"false"}>False</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item={true} xs={4}>
                        <InputLabel shrink={true}>Set Login Expires - 12am Pacific Time</InputLabel>
                            <DatePicker
                                value={bulkLoginExpires ? bulkLoginExpires : null}
                                onChange={setBulkLoginExpires}
                                format={"DD MMMM YYYY"}
                            />
                    </Grid>
                    <Grid item={true} xs={4}>
                        <InputLabel shrink={true}>Rostered</InputLabel>
                        <Select
                            value={bulkRoster}
                            displayEmpty={true}
                            onChange={(e) => setBulkRoster(e.target.value)}
                        >
                            <MenuItem key={""} value={""}>No change</MenuItem>
                            <MenuItem key={0} value={"true"}>True</MenuItem>
                            <MenuItem key={1} value={"false"}>False</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item={true} xs={4}>
                        <InputLabel shrink={true}>Set Permissions</InputLabel>
                        <List>
                            {
                                Object.values(Permissions).map((permission: string) => {
                                    return (
                                        <ListItem
                                            key={permission}
                                            button={true}
                                            onClick={() => setPermissions(permission)}
                                        >
                                            <Checkbox
                                                checked={bulkPermissions.indexOf(permission) > -1 && true}
                                            />
                                            <ListItemText primary={permission}/>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </Grid>

                    <Grid item={true} xs={12} style={{textAlign: "right"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleBulkConfirmationClick}
                        >
                            Apply to Selected
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            {bulkConfirmation && (
                <SSOBulkConfirm
                    handleOK={closeBulkConfirmationProceed}
                    handleCancel={closeBulkConfirmationCancel}
                    countToChange={selectedIds.length}
                />
            )}
        </Grid>
    )
}

export const LoginTool: React.FC = () => {
    const tableRef = useRef<MaterialTable<ApiLogin>>(null)
    const [selectedIds, setSelectedIds] = useState<any[]>([])
    const [schedules, setSchedules] = React.useState<P360Scheduling[]>([])

    useEffect(() => {
        if (schedules === undefined || schedules.length === 0) {
            getScheduleObjects()
        }
    }, [])

    const handleSelectionChange = (rows: any[]): void => {
        setSelectedIds([...rows])
    }

    const getScheduleObjects = () => {
        DevToolsApi.getP360ScheduleList().then((result: P360Scheduling[]) => {
            // ********** Alphabetize schedules ***********
            const alphabetizedSchedules = result.sort(
                (scheduleA, scheduleB) => {
                    const scheduleALowerCase = scheduleA.title.toLowerCase()
                    const scheduleBLowerCase = scheduleB.title.toLowerCase()
                    if (scheduleALowerCase > scheduleBLowerCase) {
                        return 1
                    }
                    if (scheduleALowerCase < scheduleBLowerCase) {
                        return -1
                    } else {
                        return 0
                    }
                }
            )
            // ********** End Alphabetize Schedules ***********
            setSchedules(alphabetizedSchedules)
        })
    }

    return (
        <Grid container={true} spacing={2} direction="column">
            <Grid item={true} container={true} xs={12}>
                <Grid item={true} xs={6} style={{paddingRight: theme.spacing(1)}}>
                    <BulkUpdateControls tableRef={tableRef} selectedIds={selectedIds} />
                </Grid>
                <Grid item={true} xs={6} style={{paddingLeft: theme.spacing(1)}}>
                    <Proctor247CreationTool selectedIds={selectedIds} />
                </Grid>
            </Grid>
            <Grid item={true} xs={12}>
                <BulkP360Controls tableRef={tableRef} selectedIds={selectedIds} schedules={schedules}/>
            </Grid>
            <Grid item={true} xs={12}>
                <LoginTable tableRef={tableRef} handleSelectionChange={handleSelectionChange} schedules={schedules}/>
            </Grid>
        </Grid>
    )
}
