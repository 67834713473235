
export interface AssessmentGroupInfo {
    testCode: string,
    loginId: number,
    contentAreaId: number
}

export interface GroupMaster {
    id: number
    name: string
    groupType: GroupType
    defaultGroup: boolean | undefined
    canView: boolean | undefined
    testingGroupInfo: AssessmentGroupInfo[]
}

export function sortGroupMasterByName(groups: GroupMaster[]): GroupMaster[] {
    if (groups.length === 0) {
        return groups
    }
    return groups.sort((a: GroupMaster, b: GroupMaster) => {
        if (a.name < b.name) {
            return -1
        } else if (a.name > b.name) {
            return 1
        } else {
            return 0
        }
    })
}

export enum GroupType {
    STATE = "ST",
    DISTRICT = "D",
    SCHOOL = "SC",
    CLASS = "CL",
    CUSTOMER = "C",
    LOCATION = "L",
}
