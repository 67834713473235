import React from "react"
import {ItemContent} from "../../app/common/item/models/ItemContent"

import {IItem, LoginProductContentAreaData, TLocalAnswer} from "../../types/types"
import {APT_PRODUCT_ID} from "../../util/Constants"

interface IComponentProps {
    item: IItem
    answer: TLocalAnswer
    product: LoginProductContentAreaData
    handleAnswerChange: (localAnswer: TLocalAnswer) => void
}

export default class PictureClick extends React.Component<IComponentProps, {}> {
    private pictureClickImages: Map<number, HTMLImageElement> = new Map()
    private pictureClickChecks: Map<number, HTMLDivElement> = new Map()

    constructor(props: IComponentProps) {
        super(props)
    }

    onClickPicture = (id: number) => {
        this.props.handleAnswerChange(id)
    }

    keyPressPicture = (e: any, id: number) => {
        if (e.key == "Enter") {
            this.props.handleAnswerChange(id)
        }
    }

    renderPictureClickRow = (item: IItem, row: ItemContent[]) => {
        const selectedImgId = this.props.answer
        let isFirstColumn = true
        return (
            <div key={Math.random()} className="picture-click__images__row">
                {row.map((itemContent: ItemContent) => {
                    let imgClassName = "picture-click__images__column__image"
                    if (
                        item.correctAnswers &&
                        this.props.answer !== item.correctAnswers.indexOf(itemContent.id.toString()) &&
                        item.correctAnswers.indexOf(itemContent.id.toString()) > -1
                    ) {
                        imgClassName = `${imgClassName} correct-answer`
                    }
                    if (itemContent.id === selectedImgId) {
                        imgClassName = `${imgClassName} picture-click__images__column__image--selected`
                    }

                    let columnClass = "picture-click__images__column"
                    if (isFirstColumn) {
                        columnClass = `${columnClass} picture-click__images__column--first-column`
                    }

                    const out = (
                        <div data-tst-id="selectable-response" key={itemContent.id} className={columnClass}>
                            {itemContent.id === selectedImgId && (
                                <div
                                    className="picture-click__images__column__icon"
                                    data-tst-id={itemContent.id}
                                    ref={(span: HTMLDivElement) => this.pictureClickChecks.set(itemContent.id, span)}
                                >
                                    <i className="material-icons picture-click__images__column__icon__check">done</i>
                                </div>
                            )}
                            <img
                                draggable={false}
                                src={itemContent.content as string}
                                className={imgClassName}
                                onClick={() => this.onClickPicture(itemContent.id)}
                                onKeyPress={(e) => this.keyPressPicture(e, itemContent.id)}
                                ref={(img: HTMLImageElement) => this.pictureClickImages.set(itemContent.id, img)}
                                tabIndex={0}
                                alt=""
                            />
                        </div>
                    )

                    isFirstColumn = false

                    return out
                })}
            </div>
        )
    }

    render() {
        const item: IItem = this.props.item
        this.pictureClickImages = new Map<number, HTMLImageElement>()
        this.pictureClickChecks = new Map<number, HTMLDivElement>()
        const rows: ItemContent[][] = []
        let currentRow: ItemContent[] = []

        item.choices.forEach((choice: ItemContent) => {
            currentRow.push(choice)
            if (currentRow.length === 2) {
                rows.push(currentRow)
                currentRow = []
            }
        })

        const isApt = this.props.product.productId === APT_PRODUCT_ID.value()

        return (
            <div className="picture-click">
                {!isApt && <p className="picture-click__prompt">Choose the best image.</p>}
                <div key={item.id} className="picture-click__images">
                    {rows.map((row: ItemContent[]) => {
                        return this.renderPictureClickRow(item, row)
                    })}
                </div>
            </div>
        )
    }
}
