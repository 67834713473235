import * as React from "react"
import {
    Grid,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Switch,
    Typography
} from "@material-ui/core"
import {H2Text, SmallLabelText} from "../../../../styles/AvantTypography"
import {PanelGraphWithLanguage, SSOLicenseCount} from "../../../../types/types"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import {ChangeEvent} from "react"
import {DatePicker} from "@mui/x-date-pickers"
import moment, {Moment} from "moment"

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}))

interface SSOBulkClassSetupProps {
    handleBulkApplyButtonClick: () => void
    bulkDate: string | null | undefined
    handleBulkDateChange: (date: Moment | null) => void
    bulkPanelGraph: number
    handleBulkLanguageChange: (event: React.ChangeEvent<{ name?: string; value: any }>) => void
    bulkUseHandwritten: boolean
    handleBulkUseHandwrittenToggle: (event: ChangeEvent<HTMLInputElement>) => void
    isHandwrittenSold: boolean
    isValidBulkDate: boolean
    testsForSelectedClassGroups: PanelGraphWithLanguage[] | null,
    licenses_sold: SSOLicenseCount[] | null
    totalCount: number
}

export const SSOBulkClassSetup: React.FC<SSOBulkClassSetupProps> = (props) => {
    const classes = useStyles()
    const testList = props.testsForSelectedClassGroups

    return (
        <Paper elevation={3} className={classes.paper}>
            <H2Text>Bulk Class Setup</H2Text>
            <Grid container spacing={3}>

                <Grid item xs={4}>
                        <DatePicker
                            label="Estimated Testing Date"
                            value={props.bulkDate ? moment(props.bulkDate) : undefined}
                            onChange={props.handleBulkDateChange}
                            disablePast={true}
                        />
                </Grid>

                <Grid item xs={4}>

                    <SmallLabelText>Language to Test</SmallLabelText>
                    <Select id="testingLanguage"
                            value={props.bulkPanelGraph}
                            onChange={props.handleBulkLanguageChange}
                            disabled={!testList}
                            title={testList ? "Select Language" : "Use the checkboxes to select class groups, all with the same product reservations."}
                    >
                        <MenuItem key={-1} value={-1}>Select Language</MenuItem>)
                        {testList && (
                            testList.map((test: PanelGraphWithLanguage, index) => {
                                return (<MenuItem key={index}
                                                  value={index}> {test.languageName}</MenuItem>)
                            })
                        )}
                    </Select>

                </Grid>

                <Grid item xs={4}>
                    <SmallLabelText>Use Handwritten (where allowed)</SmallLabelText>
                    <Switch
                        onChange={props.handleBulkUseHandwrittenToggle}
                        checked={props.bulkUseHandwritten}
                        disabled={props.isHandwrittenSold === false}
                        inputProps={{"aria-label": "Use for bulk change for the handwritten setting"}}
                        color={"primary"}
                    />
                </Grid>

                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Tooltip title={props.totalCount <= 0 ? "Use the checkboxes to select class groups to set up." : "Click to apply these settings to the selected class groups."}>
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={ props.totalCount <= 0 || props.bulkPanelGraph < 0 || props.isValidBulkDate === false}
                                onClick={props.handleBulkApplyButtonClick}
                            >
                                Apply to Selected
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography>
                        IMPORTANT: You will be unable to change the test package, language of the test, testing date or
                        handwritten option once you press "Apply to Selected" and then you press "Proceed" to set up the
                        tests for the classes.  Please review the selected classes before proceeding.
                    </Typography>
                </Grid>

            </Grid>
        </Paper>

    )
}
