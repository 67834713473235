/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import {ContentAreaId, ProductId} from "../validation/ValidPrimaryKey"
import {AppConfig} from "../types/types"

declare const appConfigs: AppConfig

export const SAMPLE_TEST_KEY = "is a sample test"

export const ADVANCE_LWR_CASE: string = "advance"
export const APT_LWR_CASE: string = "apt"
export const PLACE_LWR_CASE: string = "place"
export const STAMP4SE_LWR_CASE: string = "stamp4se"
export const STAMP4S_LWR_CASE: string = "stamp4s"
export const ADMIN_LWR_CASE: string = "admin"
export const JEENIE_LWR_CASE: string = "jeenie"
export const WORLDSPEAK_LWR_CASE: string = "worldspeak"
export const READY_LWR_CASE: string = "ready"
export const STAMP_SIGN_LANGUAGE_LWR_CASE: string = "stampsignlanguage"
export const APP_LWR_CASE: string = "app"
export const CLEVER_LWR_CASE: string = "clever"
export const STAMP_CEFR_LWR_CASE: string = "cefr"
export const CLASSLINK_LWR_CASE: string = "classlink"
export const NYCDOE_LWR_CASE: string = "nycdoe"
export const SAML_LWR_CASE: string = "saml"

export const WEBRTC_RESPONSE = "webRTC:"
export const MINIMUM_VOICE_RECORDING_RECORDING_LENGTH = 3500

export const REPORTS_MAX_MINUTES_TO_DISPLAY = 999  // This is in minutes

export const SUPPORT_EMAIL = "https://avantassessment.com/contact-us"
export const CONTACT_US_URL = "https://avantassessment.com/contact-us"
export const SUPPORT_MESSAGE = `Sorry, an error occurred. Please contact ${SUPPORT_EMAIL}.`

export const WRITING_RESPONSE_URL = appConfigs.HW_BASE_URL

export const BANNER_MESSAGE = "Welcome to the new Avant Reports. Access Classic Reports"

export enum StatusSymbols {
    SECTION_NOT_RATABLE = "NR",
    SECTION_NOT_STARTED = "NS",
    SECTION_IN_PROGRESS = "IP",
    SCORING_IN_PROGRESS = "SP",
    SCORED_BY_EDUCATOR = "RBE",
    SECTION_PENDING = "PD",
    SECTION_PRINTABLE = "PB",
    SECTION_PRINTED = "PR",
    TEST_STARTED = "ST",
    TEST_COMPLETE = "TC",
    SECTION_IN_ERROR = "ER",
    NOT_AVAILABLE = "-",
    EARLY_EXIT = "EE"
}

export enum ProctorTypes {
    DIYPROCTOR = "diyproctor",
    P360 = "P360",
    PROCTORTRACK = "proctortrack",
    AP_24_7 = "P360-24-7",
    EXAMITY = "examity", // Deprecated
    SELF = "self", // Deprecated
    AVANTPROCTOR = "avantproctor", // Deprecated
    EDUPROCTOR = "eduproctor" // Deprecated
}

export enum ProctorTypeNames {
    DIYPROCTOR = "DIY Proctor",
    P360 = "Avant Proctor",
    AP_24_7 = "AP 24/7",
    PROCTORTRACK = "Proctor Track",
    EXAMITY = "Examity", // Deprecated
    SELF = "Self Proctored", // Deprecated
    AVANTPROCTOR = "Avant Proctor", // Deprecated
    EDUPROCTOR = "Edu Proctor" // Deprecated
}

export enum MultifactorAuthTypes {
    TFA = "twofactor"
}

export const STAMP_4SE_PRODUCT_ID: ProductId = new ProductId(1)
export const STAMP_4S_PRODUCT_ID: ProductId = new ProductId(2)
export const PLACE_PRODUCT_ID: ProductId = new ProductId(3)
export const APT_PRODUCT_ID: ProductId = new ProductId(4)
export const WORLDSPEAK_PRODUCT_ID: ProductId = new ProductId(5)
export const READY_PRODUCT_ID: ProductId = new ProductId(6)
export const STAMP_SIGN_LANGUAGE_PRODUCT_ID: ProductId = new ProductId(13)
export const ADVANCE_PRODUCT_ID: ProductId = new ProductId(8)
export const JEENIE_PRODUCT_ID: ProductId = new ProductId(9)
export const ADMIN_PRODUCT_ID: ProductId = new ProductId(10)
export const STAMP_CEFR_PRODUCT_ID: ProductId = new ProductId(15)
export const CLEVER_SSO_LOGIN_PRODUCT_ID: ProductId = new ProductId(11)
export const CLASSLINK_SSO_LOGIN_PRODUCT_ID: ProductId = new ProductId(14)
export const NYCDOE_SSO_LOGIN_PRODUCT_ID: ProductId = new ProductId(17)
export const SAML_SSO_LOGIN_PRODUCT_ID: ProductId = new ProductId(18)
export const PRODUCT_ID_TO_NAME = {
    1: STAMP4SE_LWR_CASE,
    2: STAMP4S_LWR_CASE,
    3: PLACE_LWR_CASE,
    4: APT_LWR_CASE,
    5: WORLDSPEAK_LWR_CASE,
    6: READY_LWR_CASE,
    8: ADVANCE_LWR_CASE,
    9: JEENIE_LWR_CASE,
    10: ADMIN_LWR_CASE,
    11: CLEVER_LWR_CASE,
    15: STAMP_CEFR_LWR_CASE,
    13: STAMP_SIGN_LANGUAGE_LWR_CASE,
    14: CLASSLINK_LWR_CASE,
    17: NYCDOE_LWR_CASE,
    18: SAML_LWR_CASE
}
export const PRODUCTS_WITHOUT_PANELS = ["7","8","10","11","12","14","16"]

export const CONTENT_AREA_ID_TO_LANGUAGE_NAME = {
    0: "None",
    1: "Japanese",
    2: "Spanish",
    3: "French",
    4: "German",
    5: "English",
    6: "Chinese Simplified",
    7: "Chinese Traditional",
    8: "Turkish",
    9: "Hebrew",
    10: "Hindi",
    11: "Swahili",
    12: "Italian",
    13: "Yoruba",
    14: "Urdu",
    15: "Korean",
    16: "African Languages",
    17: "Persian-Farsi",
    18: "Arabic",
    19: "Indonesian",
    21: "Russian",
    22: "Cantonese",
    24: "Bengali",
    28: "Greek",
    30: "Thai",
    31: "Vietnamese",
    33: "Portuguese",
    34: "Punjabi",
    35: "Tamil",
    39: "Polish",
    46: "Filipino",
    92: "Yup'ik",
    93: "Somali Maxaa",
    94: "Somali Maay Maay",
    95: "Español", // Spanish Heritage
    96: "Arabic Yes-No 3-5",
    97: "Arabic Yes-No 6-8",
    98: "Arabic Yes-No 9-10",
    99: "Sandbox",
    100: "Ilocano",
    101: "Haitian Creole",
    102: "Armenian",
    103: "Samoan",
    104: "Amharic",
    105: "Hmong",
    106: "Chin (Hakha)",
    107: "Czech",
    108: "Telugu",
    109: "Marathi",
    110: "Marshallese",
    111: "Chuukese",
    112: "Navajo",
    113: "Spanish~Medical",
    114: "English~CEFR",
    115: "Kannada",
    116: "Hawaiian",
    117: "Zomi",
    119: "Spanish Monolingual",
    120: "American Sign Language",
    121: "Cabo Verdean",
    122: "Latin",
    123: "Ukrainian",
    124: "Test Language",
    125: "APT Grade 3-5",
    126: "APT Grade 6-8",
    127: "APT Grade 9+",
    128: "Hebrew 2-6",
    129: "Hebrew 7-16",
    130: "Chaldean",
    131: "Pashto",
    132: "Cambodian",
    133: "Nepali",
    134: "Tigrinya",
}

export const MULTIPLE_LANGUAGES = "Multiple Languages"
//Images for dashboard
export const RECEPTIVE="https://cdn.avantassessment.com/resources/common/img/img-2020/Receptive.svg"
export const EXPRESSIVE="https://cdn.avantassessment.com/resources/common/img/img-2020/Expressive.svg"
export const NOELLA_IMAGES="https://cdn.avantassessment.com/resources/noella_images/"
export const IMAGES_BASE_URL="https://cdn.avantassessment.com/resources/common/img/img-2020/"
export const CHECKMARK="https://cdn.avantassessment.com/resources/common/img/img-2020/checkmark.svg"
export const LISTENING="https://cdn.avantassessment.com/resources/common/img/img-2020/Listening.svg"
export const SELFEVALUATION = "https://cdn.avantassessment.com/resources/common/img/se-icon.svg"

export const CONTENT_AREA_ID_ARABIC = 18
export const CONTENT_AREA_ID_ENGLISH = 5
export const RTL_CONTENT_AREA_IDS = [9, 14, 16, 17, 18, 96, 97, 98]
export const SHL_CONTENT_AREA_ID: ContentAreaId = new ContentAreaId(95)

// These two constants are used to compute the rolling average
// of the testers volume over time. The equalizer is about representing
// tester voice volume IN THE MOMENT. However, if we were to just
// use the exact current volume the equalizer would jump all over the place.
//
// To remedy this we record a 'history' of the volumes and compute an average.
//
// The Mic Strength is supposed to represent the overall volume of the recording,
// so it's history is longer.
//
// The values are arbitrary in that they have no connection to any real world representation.
// The Twilio SDK is supposed to supply 60 samples per second, but basing these numbers
// off that rate results in undesirable behaviour. These values are the results of tweaks and experimentation,
// and their correctness is judged based on the quality of the user experience and nothing else.
export const VOLUME_HISTORY_EQUALIZER = 15
export const VOLUME_HISTORY_MIC_STRENGTH = 50

// An arbitrary limit that feels good. Twilio
// provides a value between 0 and 1, but I (trevor@twentyideas.com)
// found that 1 was too high, I never filled the bar up.
// 0.3 felt like a sweet spot. At the end of the day the visualization
// should convey "you are speaking loud enough to hear" so it should
// display green if that's true, and it's true at this value.
export const VOICE_RECORDER_MAX_VOLUME = 0.3

// This is a measurement of how much "volume"
// a segment needs before it is considered "filled"
// (which marks it with a color instead of keeping it
// grey, which denotes empty)
export const VOICE_RECORDER_VOLUME_PER_SEGMENT = 0.06

// Arbitrary value to determine if the tester needs to speak up or has mic problems.
// You must have more than this many segments to have a "Strong" Volume
export const VOLUME_STRENGTH_THRESHOLD = 4

export const VOLUME_STRENGTH_WEAK = "Weak"
export const VOLUME_STRENGTH_STRONG = "Strong"

// How many volume samples before we check if volume has been loud enough?
export const SAMPLES_TILL_VOLUME_CHECK = 500

export const LOGIN_NAME_NOT_FOUND: string = "login name not found"
export const LOGIN_NAME_FOUND: string = "login name found"
export const MASQUARADE: string = "masquarading_allowed"
export const MANAGE_ADMIN_PORTAL_LOGINS: string = "manage_admin_portal_logins_allowed"
export const MANAGE_ADVANCE_DISTRICT_LOGINS: string = "manage_advance_district_logins_allowed"
export const MANAGE_PLACE_DISTRICT_LOGINS: string = "manage_place_district_logins_allowed"
export const MANAGE_SIGN_LANGUAGE_DISTRICT_LOGINS: string = "manage_sign_language_district_logins_allowed"
export const MANAGE_PUBLIC_API_LOGINS: string = "manage_public_api_logins_allowed"

export const ROSTERED_LOGIN_NAME_NOT_FOUND: string = "Rostered login name not found"

export const LevelBenchmarks = {
    1: "Novice Low",
    2: "Novice Mid",
    3: "Novice High",
    4: "Intermediate Low",
    5: "Intermediate Mid",
    6: "Intermediate High",
    7: "Advanced Low",
    8: "Advanced Mid",
    9: "Advanced High",
    10: "Superior",
}
