/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {AppBar, Box, Tab, Tabs, Typography} from "@material-ui/core"
import React from "react"
import {RouteComponentProps} from "react-router-dom"
import {CreateAssessment} from "../CreateAssessment"
import {AppSettingsAdmin} from "./AppSettingsAdmin"
import {BasicTools} from "./BasicTools"
import {CustomSectionMapAdmin} from "./CustomSectionMapAdmin"
import {LoginTool} from "./LoginTool"
import {MessageWallAdmin} from "./MessageWallAdmin"
import {PanelAdminBase} from "./PanelAdmin"
import {PanelGraphAdmin} from "./PanelGraphAdmin"
import {ValidateRecordings} from "./ValidateRecordings"
import {LoginToolNew} from "./LoginTableNew/LoginToolNew"
import {SSOReportTools} from "./SSOEntityReports/SSOReportTools"
import SamlIdpConfigTool from "./SamlIdpConfigTool/SamlIdpConfigTool"

interface State {
    selectedTab: number
}

interface TabPanelProps {
    children?: React.ReactNode,
    index: number,
    value: number,
}
export const TabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

function tabProps(index: number) {
    return {
        id: `devtool-tab-${index}`,
        "aria-controls": `dev tools  ${index}`,
    }
}
export default class DevTools extends React.Component<RouteComponentProps, State, {}> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            selectedTab: 0
        }
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({"selectedTab": newValue})
    }

    render() {
        return (
            <>
                <h1>Developer Tools</h1>
                <p>These tools are intended for developers only.</p>
                <AppBar position="static">
                    <Tabs value={this.state.selectedTab} variant="scrollable" onChange={this.handleChange} aria-label="Developer tools" scrollButtons="on" >
                        <Tab label="Basic Tools" {...tabProps(0)} />
                        <Tab label="Test Creation" {...tabProps(1)} />
                        <Tab label="Login Test" {...tabProps(2)} />
                        <Tab label="Validate Recordings on S3" {...tabProps(3)} />
                        <Tab label="Message Wall" {...tabProps(4)} />
                        <Tab label="Panel Graphs" {...tabProps(5)} />
                        <Tab label="Panels" {...tabProps(6)} />
                        <Tab label="AppSettings" {...tabProps(7)} />
                        <Tab label="Custom Sections" {...tabProps(8)} />
                        <Tab label="New Data Grid" {...tabProps(9)} />
                        <Tab label="New SSO Report Tool" {...tabProps(10)} />
                        <Tab label="SAML IDP Tool" {...tabProps(11)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.selectedTab} index={0}>
                    <BasicTools/>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={1}>
                    <CreateAssessment/>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={2}>
                    <LoginTool/>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={3}>
                    <ValidateRecordings/>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={4}>
                    <MessageWallAdmin/>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={5}>
                    <PanelGraphAdmin/>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={6}>
                    <PanelAdminBase/>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={7}>
                    <AppSettingsAdmin/>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={8}>
                    <CustomSectionMapAdmin/>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={9}>
                    {/*<h1>Coming Soon</h1>*/}
                    {/*<LoginTableNew />*/}
                    <LoginToolNew />
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={10}>
                    <SSOReportTools />
                </TabPanel>
                <TabPanel index={11} value={this.state.selectedTab}>
                    <SamlIdpConfigTool />
                </TabPanel>
            </>
        )
    }
}
