/*
 * ******************************************************
 *  * Copyright (C) 2010-Present Avant Assessment
 *  * All Rights Reserved
 *  ******************************************************
 */

import React from "react"
import {
    Checkbox,
    // FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent
} from "@mui/material"
import {themeV5} from "../../../../styles/MuiThemes"
import {H2Text} from "../../../../styles/AvantTypography"
import {DatePicker} from "@mui/x-date-pickers"
import Button from "@mui/material/Button"
import {Moment} from "moment"
import {FormControlLabel} from "@material-ui/core"
import {TeacherPermissions, TestTakerPermissions} from "../LoginTool"
import {Stack} from "@mui/system"
import {AdminLoginTableData} from "../../../../types/rest/LoginTypes"
import {BulkP360LoginScheduleTool} from "./BulkP360LoginScheduleTool"

// @ts-ignore
interface BulkUpdateApiValues {
    handwritten: boolean | undefined
    loginExpires: Moment | undefined
    roster: boolean | undefined
    setSelectedAsP360: boolean | undefined
    listOfLoginIds: number[]
}

interface BulkLoginUpdateControlProps {
    selectedTableData: AdminLoginTableData[]
}

interface BulkSelect {
    key: number,
    value: string,
    label: string
}

const allPermissions = { ...TeacherPermissions, ...TestTakerPermissions }

const BulkSelectValues: BulkSelect[] = [
    {
        key: -1,
        value: "",
        label: "No Change to"
    },
    {
        key: 1,
        value: "true",
        label: "Make All selected Logins "
    },
    {
        key: 2,
        value: "false",
        label: "Make All Selected Logins NOT "
    }
]

interface BulkLoginUpdateControlState {
    handwritten: string
    roster: string
    p360: string,
    selectedTableData: AdminLoginTableData[]
}


export const BulkLoginUpdateControl: React.FC<BulkLoginUpdateControlProps> = (props) => {

    const [localState, setLocalState] = React.useState<BulkLoginUpdateControlState>({
        handwritten: "",
        roster: "",
        p360: "",
        selectedTableData: []
    })

   const handleHandwrittenChange = (event: SelectChangeEvent<typeof localState.handwritten>) => {
        console.log(event.target.value)
        setLocalState({
            ...localState,
            handwritten: event.target.value
        })
    }

    const handleRosterChange = (event: SelectChangeEvent<typeof localState.roster>) => {
        console.log(event.target.value)
        setLocalState({
            ...localState,
            roster: event.target.value
        })
    }

    const handleP360Change = (event: SelectChangeEvent<typeof localState.p360>) => {
        setLocalState({
            ...localState,
            p360: event.target.value
        })
    }

    const getMenuItems = (headingModifier: string) => {
        return (
            BulkSelectValues.map((value: BulkSelect) => {
                    return (
                        <MenuItem key={`${value.key}-${headingModifier}`} value={value.value}>{`${value.label} ${headingModifier}`}</MenuItem>
                    )
                }
            )
        )
    }

    return (
        <Grid item={true} xs={12} style={{paddingBottom: themeV5.spacing(1)}}>
            <Paper
                elevation={3}
                style={{
                    paddingTop: themeV5.spacing(2),
                    paddingBottom: themeV5.spacing(2),
                    paddingLeft: themeV5.spacing(3),
                    paddingRight: themeV5.spacing(3)
                }}
            >
                <H2Text>Bulk Change Tool</H2Text>
                <Grid container={true} spacing={3}>
                    <Grid item={true} xs={4}>
                        <InputLabel shrink={false}>Enable Handwritten</InputLabel>
                        <Select
                            fullWidth={true}
                            value={localState.handwritten}
                            displayEmpty={true}
                            onChange={handleHandwrittenChange}
                        >
                            {getMenuItems("handwritten")}
                        </Select>
                    </Grid>
                    <Grid item={true} xs={4}>
                        <InputLabel shrink={false}>Set Login Expires - 12am Pacific Time</InputLabel>
                        <DatePicker
                            sx={{width: "100%"}}
                            // value={bulkLoginExpires ? bulkLoginExpires : null}
                            // onChange={setBulkLoginExpires}
                            format={"DD MMMM YYYY"}
                        />
                    </Grid>
                    <Grid item={true} xs={4}>
                        <InputLabel shrink={false}>Enable Rostering</InputLabel>
                        <Select
                            fullWidth={true}
                            value={localState.roster}
                            displayEmpty={true}
                            onChange={handleRosterChange}
                        >
                            {getMenuItems("rostered")}
                        </Select>
                    </Grid>

                    <Grid item={true} xs={8}>
                        <Paper
                            elevation={3}
                            style={{
                                paddingTop: themeV5.spacing(2),
                                paddingBottom: themeV5.spacing(2),
                                paddingLeft: themeV5.spacing(3),
                                paddingRight: themeV5.spacing(3),
                                height: "100%"
                            }}
                        >
                            <InputLabel shrink={false}>Set Permissions</InputLabel>
                            <Stack direction={"row"} spacing={themeV5.spacing(1)} alignItems={"center"}>
                            {
                                Object.values(allPermissions).map((permission: string, index: number) => {
                                    return (<FormControlLabel id={`SetPermissionSelect-${permission}-${index}`} key={`${permission}-${index}`} control={<Checkbox />} label={permission} />)
                                })
                            }
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item={true} xs={4}>
                        <Paper
                            elevation={3}
                            style={{
                                paddingTop: themeV5.spacing(2),
                                paddingBottom: themeV5.spacing(2),
                                paddingLeft: themeV5.spacing(3),
                                paddingRight: themeV5.spacing(3),
                                height: "100%"
                            }}
                        >
                            <InputLabel shrink={false}>Set P360</InputLabel>
                            <Select
                                fullWidth={true}
                                value={localState.p360}
                                displayEmpty={true}
                                onChange={handleP360Change}
                            >
                                {getMenuItems("P360")}
                            </Select>
                        </Paper>
                    </Grid>
                    <Grid item={true} xs={9}>
                        <BulkP360LoginScheduleTool/>
                    </Grid>
                    <Grid
                        item={true}
                        xs={3}
                        style={{textAlign: "right", display: "flex", alignItems: "center", justifyContent: "flex-end"}}
                        // justifyContent={"flex-end"}
                        // alignItems={"center"}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={props.selectedTableData.length < 1}
                            // onClick={handleBulkConfirmationClick}
                        >
                            Apply to Selected
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )

}

